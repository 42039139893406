// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, PMStepper, PMButton, FormWidget, DetailWidget, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useCustomerListQuery } from "../store/customerListSlice";

import { useProductVariantListQuery } from "../store/productVariantListSlice";

import { useCustomerEmailCreateMutation } from "../store/customerEmailCreateSlice";
import { default as emailCreateFormatter } from "../transformations/emailCreateFormatter";
import { useCustomerPhoneCreateMutation } from "../store/customerPhoneCreateSlice";
import { default as phoneCreateFormatter } from "../transformations/phoneCreateFormatter";
import { useCustomerCreateMutation } from "../store/customerCreateSlice";

import { useCustomerAddressListQuery } from "../store/customerAddressListSlice";

import { usePricingListQuery } from "../store/pricingListSlice";

import { useCustomerEmailListQuery } from "../store/customerEmailListSlice";

import { useCustomerPhoneListQuery } from "../store/customerPhoneListSlice";

import { useCustomerAddressCreateMutation } from "../store/customerAddressCreateSlice";
import { default as AddressCreateFormatter } from "../transformations/AddressCreateFormatter";
import { useShipAddressCreateMutation } from "../store/shipAddressCreateSlice";
import { default as shipAddressCreateFormatter } from "../transformations/shipAddressCreateFormatter";
import { useShipmentCreateMutation } from "../store/shipmentCreateSlice";
import { default as shipmentCreateFormatter } from "../transformations/shipmentCreateFormatter";
import { usePaymentCreateMutation } from "../store/paymentCreateSlice";
import { default as paymentCreateFormatter } from "../transformations/paymentCreateFormatter";
import { useOrderItemCreateMutation } from "../store/orderItemCreateSlice";
import { default as productFormSubmitFormatter } from "../transformations/productFormSubmitFormatter";
import { useNotesCreateMutation } from "../store/notesCreateSlice";
import { default as notesCreateFormatter } from "../transformations/notesCreateFormatter";
import { useOrderCreateMutation } from "../store/orderCreateSlice";
import { jsonToFormData } from "../helperFn/formData";
import { default as orderCreateFormatter } from "../transformations/orderCreateFormatter";
import { default as customerListFormatter } from "../transformations/customerListFormatter";
import { default as productVariantListFormatter } from "../transformations/productVariantListFormatter";
import { default as customerAddressListFormatter } from "../transformations/customerAddressListFormatter";
import { default as customerEmailListFormatter } from "../transformations/customerEmailListFormatter";
import { default as customerPhoneListFormatter } from "../transformations/customerPhoneListFormatter";
import { default as customerAddressUpdateFormatter } from "../transformations/customerAddressUpdateFormatter";
import { default as emailDataUpdateFormatter } from "../transformations/emailDataUpdateFormatter";
import { default as phoneDataUpdateFormatter } from "../transformations/phoneDataUpdateFormatter";
import { default as customerDataUpdateFormatter } from "../transformations/customerDataUpdateFormatter";
import { default as OrderformDataFormatter } from "../transformations/OrderformDataFormatter";
import { default as paddressUpdateFormatter } from "../transformations/paddressUpdateFormatter";
import { default as paymentFormFormatter } from "../transformations/paymentFormFormatter";
import { default as saddressUpdateFormatter } from "../transformations/saddressUpdateFormatter";
import { default as shipmentFormFormatter } from "../transformations/shipmentFormFormatter";
import { default as productFormFormatter } from "../transformations/productFormFormatter";
function OrderCreate() {
    const [customerListSkip, setcustomerListSkip] = useState(true);
    const [customerListParams, setcustomerListParams] = useState({});
    const customerListQuery = useCustomerListQuery(customerListParams, { skip: customerListSkip });
    const [productVariantListSkip, setproductVariantListSkip] = useState(true);
    const [productVariantListParams, setproductVariantListParams] = useState({});
    const productVariantListQuery = useProductVariantListQuery(productVariantListParams, {
        skip: productVariantListSkip,
    });
    const customerEmailCreateMutation = useCustomerEmailCreateMutation();
    const customerPhoneCreateMutation = useCustomerPhoneCreateMutation();
    const customerCreateMutation = useCustomerCreateMutation();
    const [customerAddressListSkip, setcustomerAddressListSkip] = useState(true);
    const [customerAddressListParams, setcustomerAddressListParams] = useState({});
    const customerAddressListQuery = useCustomerAddressListQuery(customerAddressListParams, {
        skip: customerAddressListSkip,
    });
    const [pricingListSkip, setpricingListSkip] = useState(true);
    const [pricingListParams, setpricingListParams] = useState({});
    const pricingListQuery = usePricingListQuery(pricingListParams, { skip: pricingListSkip });
    const [customerEmailListSkip, setcustomerEmailListSkip] = useState(true);
    const [customerEmailListParams, setcustomerEmailListParams] = useState({});
    const customerEmailListQuery = useCustomerEmailListQuery(customerEmailListParams, { skip: customerEmailListSkip });
    const [customerPhoneListSkip, setcustomerPhoneListSkip] = useState(true);
    const [customerPhoneListParams, setcustomerPhoneListParams] = useState({});
    const customerPhoneListQuery = useCustomerPhoneListQuery(customerPhoneListParams, { skip: customerPhoneListSkip });
    const customerAddressCreateMutation = useCustomerAddressCreateMutation();
    const shipAddressCreateMutation = useShipAddressCreateMutation();
    const shipmentCreateMutation = useShipmentCreateMutation();
    const paymentCreateMutation = usePaymentCreateMutation();
    const orderItemCreateMutation = useOrderItemCreateMutation();
    const notesCreateMutation = useNotesCreateMutation();
    const orderCreateMutation = useOrderCreateMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [formCount, setformCount] = useState(0);
    const [formDataState, setformDataState] = useState([]);
    const [pricingListData, setpricingListData] = useState([]);
    const [PaymentformState, setPaymentformState] = useState([]);
    const [shipmentformState, setshipmentformState] = useState([]);
    const [productFormData, setproductFormData] = useState([]);
    const [reviewFormData, setreviewFormData] = useState([]);
    const customerListData = useSelector((states) => states?.appStore?.customerListData);
    const customerAddressListData = useSelector((states) => states?.appStore?.customerAddressListData);
    const productVariantListData = useSelector((states) => states?.appStore?.productVariantListData);
    const customerEmailListData = useSelector((states) => states?.appStore?.customerEmailListData);
    const customerPhoneListData = useSelector((states) => states?.appStore?.customerPhoneListData);
    const { enqueueSnackbar } = useSnackbar();

    const reviewFormUpdate = (data) => {
        const responseData = data?.data || data;
        setreviewFormData(responseData);
    };

    const watchReviewFormChange = (data) => {
        reviewFormUpdate(data);
    };

    const customerAdded = () => {
        let formCountTemp = parseInt(formCount) + 1;
        setformCount(formCountTemp);
    };

    const notesCreated = (data) => {
        var formatedData = {
            notesCreatedData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const notesCreate = async (data) => {
        var apiData = notesCreateFormatter(data, reviewFormData);

        if (apiData && apiData["note"]) {
            await notesCreateMutation[0]({ order_id: apiData["id"], note: apiData["note"] }).unwrap();
        }
    };

    const notesCreateSuccess = (data) => {
        notesCreated(data);
    };
    useEffect(() => {
        if (notesCreateMutation[1].isSuccess) {
            notesCreateSuccess(notesCreateMutation[1]);
        }
        if (notesCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(notesCreateMutation[1]), { variant: "error" });
        }
    }, [notesCreateMutation]);

    const pocreated = (data) => {
        history("/orders/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Order Created", { variant: "success" });
    };

    const orderItemCreate = async (data) => {
        var apiData = productFormSubmitFormatter(data, productFormData);

        if (apiData["products"] && apiData["products"].length) {
            apiData["products"].forEach(async (apiData) => {
                if (apiData) {
                    await orderItemCreateMutation[0]({
                        order_id: apiData["order_id"],
                        product_variant_id: apiData["product_variant_id"],
                        oreder_item_status: apiData["oreder_item_status"],
                        quantity: apiData["quantity"],
                        price: apiData["price"],
                    }).unwrap();
                }
            });
        }
    };

    const orderItemCreateSuccess = (data) => {
        showSuccessAlert(data);
        pocreated(data);
    };
    useEffect(() => {
        if (orderItemCreateMutation[1].isSuccess) {
            orderItemCreateSuccess(orderItemCreateMutation[1]);
        }
        if (orderItemCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(orderItemCreateMutation[1]), { variant: "error" });
        }
    }, [orderItemCreateMutation]);

    const paymentUpdated = (data) => {
        var formatedData = {
            paymentUpdatedData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const paymentCreate = async (data) => {
        var apiData = paymentCreateFormatter(data, formDataState, PaymentformState, reviewFormData);

        if (apiData) {
            await paymentCreateMutation[0]({
                order_id: apiData["order_id"],
                payment_address_id: apiData["payment_address_id"],
                email_id: apiData["email_id"],
                phone_id: apiData["phone_id"],
                payment_mode: apiData["payment_method"],
                payment_date: apiData["payment_date"],
            }).unwrap();
        }
    };

    const paymentCreateSuccess = (data) => {
        paymentUpdated(data);
    };
    useEffect(() => {
        if (paymentCreateMutation[1].isSuccess) {
            paymentCreateSuccess(paymentCreateMutation[1]);
        }
        if (paymentCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(paymentCreateMutation[1]), { variant: "error" });
        }
    }, [paymentCreateMutation]);

    const shipmentUpdated = (data) => {
        var formatedData = {
            shipmentUpdatedData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const shipmentCreate = async (data) => {
        var apiData = shipmentCreateFormatter(data, formDataState, shipmentformState, reviewFormData);

        if (apiData) {
            await shipmentCreateMutation[0]({
                order_id: apiData["order_id"],
                shipment_address_id: apiData["shipment_address_id"],
                email_id: apiData["email_id"],
                phone_id: apiData["phone_id"],
                shipping_method: apiData["shipping_method"],
                shipment_status: apiData["shipment_status"],
                tracking_id: apiData["tracking_id"],
            }).unwrap();
        }
    };

    const shipmentCreateSuccess = (data) => {
        shipmentUpdated(data);
    };
    useEffect(() => {
        if (shipmentCreateMutation[1].isSuccess) {
            shipmentCreateSuccess(shipmentCreateMutation[1]);
        }
        if (shipmentCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(shipmentCreateMutation[1]), { variant: "error" });
        }
    }, [shipmentCreateMutation]);

    const orderCreate = async (data) => {
        var apiData = orderCreateFormatter(data, formDataState);

        if (apiData) {
            await orderCreateMutation[0](
                jsonToFormData({
                    channel: apiData["channel"],
                    order_status: apiData["order_status"],
                    phone_id: apiData["phone_id"],
                    invoice_file: apiData["invoice_file"],
                    invoice_number: apiData["invoice_number"],
                    customer_id: apiData["customer_id"],
                    email_id: apiData["email_id"],
                })
            ).unwrap();
        }
    };

    const orderCreateSuccess = (data) => {
        shipmentCreate(data);
        paymentCreate(data);
        orderItemCreate(data);
        notesCreate(data);
    };
    useEffect(() => {
        if (orderCreateMutation[1].isSuccess) {
            orderCreateSuccess(orderCreateMutation[1]);
        }
        if (orderCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(orderCreateMutation[1]), { variant: "error" });
        }
    }, [orderCreateMutation]);

    const createOrderApi = (data) => {
        orderCreate(data);

        customerAdded(data);
    };

    const productFormUpdate = (data) => {
        const responseData = data?.data || data;
        setproductFormData(productFormFormatter(responseData, productVariantListData, pricingListData));
    };

    const watchProductFormChange = (data) => {
        productFormUpdate(data);
    };

    const nextCounter = (data) => {
        customerAdded(data);
    };

    const shipmentFormUpdate = (data) => {
        const responseData = data?.data || data;
        setshipmentformState(shipmentFormFormatter(responseData, customerAddressListData, PaymentformState));
    };

    const watchShipmentFormChange = (data) => {
        shipmentFormUpdate(data);
    };

    const shipaddressDataUpdate = (data) => {
        const responseData = data?.data || data;
        setshipmentformState(saddressUpdateFormatter(responseData, shipmentformState));
    };

    const shipAddressCreate = async (data) => {
        var apiData = shipAddressCreateFormatter(data, formDataState);

        if (apiData && apiData["address"]) {
            await shipAddressCreateMutation[0]({
                city: apiData["city"],
                state: apiData["state"],
                pincode: apiData["pincode"],
                address: apiData["address"],
                customer_id: apiData["customer_id"],
            }).unwrap();
        }
    };

    const shipAddressSuccess = (data) => {
        shipaddressDataUpdate(data);
    };
    useEffect(() => {
        if (shipAddressCreateMutation[1].isSuccess) {
            shipAddressSuccess(shipAddressCreateMutation[1]);
        }
        if (shipAddressCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(shipAddressCreateMutation[1]), { variant: "error" });
        }
    }, [shipAddressCreateMutation]);

    const shipAddressCreateApi = (data) => {
        shipAddressCreate(data);

        customerAdded(data);
    };

    const paymentFormUpdate = (data) => {
        const responseData = data?.data || data;
        setPaymentformState(paymentFormFormatter(responseData, customerAddressListData));
    };

    const watchPaymentFormChange = (data) => {
        paymentFormUpdate(data);
    };

    const paddressDataUpdate = (data) => {
        const responseData = data?.data || data;
        setPaymentformState(paddressUpdateFormatter(responseData, PaymentformState, customerAddressListData));
    };

    const customerAddressUpdatedStore = (data) => {
        var formatedData = {
            ...customerAddressUpdateFormatter(data?.data || data, customerAddressListData, "customerAddressListData"),
        };
        dispatch(setStore(formatedData));
    };

    const customerAddressCreate = async (data) => {
        var apiData = AddressCreateFormatter(data, formDataState);

        if (apiData && apiData["address"]) {
            await customerAddressCreateMutation[0]({
                city: apiData["city"],
                state: apiData["state"],
                pincode: apiData["pincode"],
                address: apiData["address"],
                customer_id: apiData["customer_id"],
            }).unwrap();
        }
    };

    const customerAddressSuccess = (data) => {
        customerAddressUpdatedStore(data);
        paddressDataUpdate(data);
    };
    useEffect(() => {
        if (customerAddressCreateMutation[1].isSuccess) {
            customerAddressSuccess(customerAddressCreateMutation[1]);
        }
        if (customerAddressCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(customerAddressCreateMutation[1]), { variant: "error" });
        }
    }, [customerAddressCreateMutation]);

    const customerAddressCreateApi = (data) => {
        customerAddressCreate(data);

        customerAdded(data);
    };

    const formdataUpadte = (data) => {
        const responseData = data?.data || data;
        setformDataState(
            OrderformDataFormatter(responseData, customerListData, customerEmailListData, customerPhoneListData)
        );
    };

    const customerPhoneListStore = (data) => {
        var formatedData = {
            ...customerPhoneListFormatter(data?.data || data, "customerPhoneListData"),
        };
        dispatch(setStore(formatedData));
    };

    const customerPhoneSuccess = (data) => {
        customerPhoneListStore(data);
    };
    useEffect(() => {
        if (customerPhoneListQuery.isSuccess) {
            customerPhoneSuccess(customerPhoneListQuery);
        } else if (customerPhoneListQuery.isError) {
            customerPhoneSuccess(null);
        }
    }, [customerPhoneListQuery]);
    const customerPhoneList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        if (data && data["customer_id"]) {
            setcustomerPhoneListParams({ customer_id: data ? data["customer_id"] : "customer_id" });
            setcustomerPhoneListSkip(false);
        }
    };

    const customerEmailListStore = (data) => {
        var formatedData = {
            ...customerEmailListFormatter(data?.data || data, "customerEmailListData"),
        };
        dispatch(setStore(formatedData));
    };

    const customerEmailSuccess = (data) => {
        customerEmailListStore(data);
    };
    useEffect(() => {
        if (customerEmailListQuery.isSuccess) {
            customerEmailSuccess(customerEmailListQuery);
        } else if (customerEmailListQuery.isError) {
            customerEmailSuccess(null);
        }
    }, [customerEmailListQuery]);
    const customerEmailList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        if (data && data["customer_id"]) {
            setcustomerEmailListParams({ customer_id: data ? data["customer_id"] : "customer_id" });
            setcustomerEmailListSkip(false);
        }
    };

    const pricingListStore = (data) => {
        const responseData = data?.data || data;
        setpricingListData(responseData);
    };

    const pricingListSiuccess = (data) => {
        pricingListStore(data);
    };
    useEffect(() => {
        if (pricingListQuery.isSuccess) {
            pricingListSiuccess(pricingListQuery);
        } else if (pricingListQuery.isError) {
            pricingListSiuccess(null);
        }
    }, [pricingListQuery]);
    const pricingList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        if (data && data["channel"]) {
            setpricingListParams({ channel: data ? data["channel"] : "channel" });
            setpricingListSkip(false);
        }
    };

    const customerAddressListStore = (data) => {
        var formatedData = {
            ...customerAddressListFormatter(data?.data || data, "customerAddressListData"),
        };
        dispatch(setStore(formatedData));
    };

    const manufacturerSuccess3 = (data) => {
        customerAddressListStore(data);
    };
    useEffect(() => {
        if (customerAddressListQuery.isSuccess) {
            manufacturerSuccess3(customerAddressListQuery);
        } else if (customerAddressListQuery.isError) {
            manufacturerSuccess3(null);
        }
    }, [customerAddressListQuery]);
    const customerAddressList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        if (data && data["customer_id"]) {
            setcustomerAddressListParams({ customer_id: data ? data["customer_id"] : "customer_id" });
            setcustomerAddressListSkip(false);
        }
    };

    const watchFormChange = (data) => {
        customerAddressList(data);

        pricingList(data);

        customerEmailList(data);

        customerPhoneList(data);

        formdataUpadte(data);
    };

    const customerDataUpdate = (data) => {
        const responseData = data?.data || data;
        setformDataState(customerDataUpdateFormatter(responseData, formDataState));
    };

    const phoneDataUpdate = (data) => {
        const responseData = data?.data || data;
        setformDataState(phoneDataUpdateFormatter(responseData, formDataState));
    };

    const customerPhoneCreate = async (data) => {
        var apiData = phoneCreateFormatter(data, formDataState);

        if (apiData) {
            await customerPhoneCreateMutation[0]({
                customer_id: apiData["id"],
                phone_number: apiData["phone"],
            }).unwrap();
        }
    };

    const phoneCreateSuccess = (data) => {
        phoneDataUpdate(data);
    };
    useEffect(() => {
        if (customerPhoneCreateMutation[1].isSuccess) {
            phoneCreateSuccess(customerPhoneCreateMutation[1]);
        }
        if (customerPhoneCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(customerPhoneCreateMutation[1]), { variant: "error" });
        }
    }, [customerPhoneCreateMutation]);

    const emailDataUpdate = (data) => {
        const responseData = data?.data || data;
        setformDataState(emailDataUpdateFormatter(responseData, formDataState));
    };

    const customerEmailCreate = async (data) => {
        var apiData = emailCreateFormatter(data, formDataState);

        if (apiData) {
            await customerEmailCreateMutation[0]({
                customer_id: apiData["id"],
                email_address: apiData["email"],
            }).unwrap();
        }
    };

    const emailCreateSuccess = (data) => {
        emailDataUpdate(data);
    };
    useEffect(() => {
        if (customerEmailCreateMutation[1].isSuccess) {
            emailCreateSuccess(customerEmailCreateMutation[1]);
        }
        if (customerEmailCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(customerEmailCreateMutation[1]), { variant: "error" });
        }
    }, [customerEmailCreateMutation]);

    const customerCreate = async (data) => {
        var apiData = data;

        if (apiData && apiData["name"]) {
            await customerCreateMutation[0]({ name: apiData["name"], channel: apiData["channel"] }).unwrap();
        }
    };

    const customerCreateSuccess = (data) => {
        customerEmailCreate(data);
        customerPhoneCreate(data);
        customerDataUpdate(data);
    };
    useEffect(() => {
        if (customerCreateMutation[1].isSuccess) {
            customerCreateSuccess(customerCreateMutation[1]);
        }
        if (customerCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(customerCreateMutation[1]), { variant: "error" });
        }
    }, [customerCreateMutation]);

    const createCustomer = (data) => {
        customerCreate(data);

        customerAdded(data);
    };

    const fromBack = () => {
        let formCountTemp = parseInt(formCount) - 1;
        setformCount(formCountTemp);
    };

    const formBack = (data) => {
        fromBack(data);
    };

    const saveProductVariantListData = (data) => {
        var formatedData = {
            ...productVariantListFormatter(data?.data || data, "productVariantListData"),
        };
        dispatch(setStore(formatedData));
    };

    const productVariantListSuccess = (data) => {
        saveProductVariantListData(data);
    };
    useEffect(() => {
        if (productVariantListQuery.isSuccess) {
            productVariantListSuccess(productVariantListQuery);
        } else if (productVariantListQuery.isError) {
            productVariantListSuccess(null);
        }
    }, [productVariantListQuery]);
    const productVariantList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setproductVariantListSkip(false);
    };

    const customerListListData = (data) => {
        var formatedData = {
            ...customerListFormatter(data?.data || data, "customerListData"),
        };
        dispatch(setStore(formatedData));
    };

    const customerListSuccess = (data) => {
        customerListListData(data);
    };
    useEffect(() => {
        if (customerListQuery.isSuccess) {
            customerListSuccess(customerListQuery);
        } else if (customerListQuery.isError) {
            customerListSuccess(null);
        }
    }, [customerListQuery]);
    const customerList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcustomerListSkip(false);
    };

    useEffect(() => {
        customerList();
        productVariantList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} xs={12} justifyContent="center" sx={{ px: 1, py: 2 }}>
                    <PMStepper
                        activeStep={formCount}
                        steps={[
                            { label: "Customer Details" },
                            { label: "Payment Details" },
                            { label: "Shipment Details" },
                            { label: "Order items" },
                            { label: "Review" },
                        ]}
                    ></PMStepper>
                </PMGrid>

                <PMGrid container={true} xs={12} sx={{ px: 1, py: 2 }}>
                    {formCount != 0 ? <PMButton label="← Back" onClick={formBack}></PMButton> : ""}
                </PMGrid>

                <PMGrid container={true} xs={12}>
                    {formCount == 0 ? (
                        <FormWidget
                            direction="column"
                            header="Create New Order"
                            submitButtonLabel="continue →"
                            defaultValues={formDataState}
                            Onsubmit={createCustomer}
                            Onchange={watchFormChange}
                            fieldsets={[
                                {
                                    direction: "row",
                                    fields: [
                                        {
                                            label: "Channel",
                                            md: "4",
                                            name: "channel",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: "AMAZON", label: "Amazon" },
                                                { id: "BIGBASKET", label: "Big Basket" },
                                                { id: "RETAIL", label: "Retail" },
                                                { id: "WEBSITE", label: "Website" },
                                            ],
                                        },
                                        {
                                            label: "Customer",
                                            md: "4",
                                            name: "customer_id",
                                            type: "select",
                                            options: customerListData,
                                        },
                                    ],
                                },
                                {
                                    direction: "row",
                                    displayon: formDataState?.customer_id,
                                    header: "Add Customer",
                                    fields: [
                                        {
                                            label: "Customer Name",
                                            md: "4",
                                            name: "name",
                                            type: "text",
                                            required: true,
                                            validation: { minLength: 2 },
                                        },
                                        { label: "Email", md: "4", name: "email", type: "email", required: true },
                                        {
                                            label: "Phone Number",
                                            md: "4",
                                            name: "phone",
                                            type: "number",
                                            required: true,
                                            validation: { minLength: 10, maxLength: 10, pattern: "^\\d+$" },
                                        },
                                    ],
                                },
                                {
                                    direction: "row",
                                    displayon: !formDataState?.customer_id,
                                    header: "Customer Details",
                                    fields: [
                                        {
                                            label: "Customer Name",
                                            md: "4",
                                            name: "placeholder_name",
                                            type: "display",
                                            readOnly: true,
                                        },
                                        {
                                            label: "Email",
                                            md: "4",
                                            name: "email_id",
                                            type: "select",
                                            options: customerEmailListData,
                                            required: true,
                                        },
                                        {
                                            label: "Phone Number",
                                            md: "4",
                                            name: "phone_id",
                                            type: "select",
                                            options: customerPhoneListData,
                                            required: true,
                                        },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}
                    {formCount == 1 ? (
                        <FormWidget
                            direction="column"
                            header="Payment Details"
                            submitButtonLabel="continue →"
                            defaultValues={PaymentformState}
                            Onsubmit={customerAddressCreateApi}
                            Onchange={watchPaymentFormChange}
                            fieldsets={[
                                {
                                    direction: "row",
                                    header: "Select From Existing Address",
                                    fields: [
                                        {
                                            label: "Select Address",
                                            md: "4",
                                            name: "payment_address_id",
                                            type: "select",
                                            options: customerAddressListData,
                                        },
                                    ],
                                },
                                {
                                    direction: "row",
                                    fields: [
                                        { label: "Address", name: "placeholder_address", type: "display" },
                                        { label: "City", name: "placeholder_city", type: "display" },
                                        { label: "State", md: "4", name: "placeholder_state", type: "display" },
                                        { label: "Pin Code", md: "4", name: "placeholder_pincode", type: "display" },
                                    ],
                                },
                                {
                                    direction: "row",
                                    displayon: PaymentformState?.payment_address_id,
                                    header: "Create New Address",
                                    fields: [
                                        { label: "Address", name: "address", type: "text", required: true },
                                        { label: "City", name: "city", type: "text", required: true },
                                        { label: "State", md: "4", name: "state", type: "text", required: true },
                                        {
                                            label: "Pin Code",
                                            md: "4",
                                            name: "pincode",
                                            type: "number",
                                            required: true,
                                            validation: { minLength: 6, maxLength: 6, pattern: "^\\d+$" },
                                        },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}
                    {formCount == 2 ? (
                        <FormWidget
                            direction="column"
                            header="Shipment Details"
                            submitButtonLabel="continue →"
                            defaultValues={shipmentformState}
                            Onsubmit={shipAddressCreateApi}
                            Onchange={watchShipmentFormChange}
                            fieldsets={[
                                {
                                    direction: "row",
                                    header: "Select From existing Address",
                                    fields: [
                                        {
                                            label: "Same as Payment Address",
                                            md: "4",
                                            name: "same_address",
                                            type: "checkbox",
                                        },
                                        {
                                            label: "Select Address",
                                            md: "4",
                                            name: "shipment_address_id",
                                            type: "select",
                                            options: customerAddressListData,
                                            disabled: shipmentformState?.same_address,
                                        },
                                    ],
                                },
                                {
                                    direction: "row",
                                    fields: [
                                        { label: "Address", name: "placeholder_address", type: "display" },
                                        { label: "City", name: "placeholder_city", type: "display" },
                                        { label: "State", md: "4", name: "placeholder_state", type: "display" },
                                        { label: "Pin Code", md: "4", name: "placeholder_pincode", type: "display" },
                                    ],
                                },
                                {
                                    direction: "row",
                                    displayon: shipmentformState?.shipment_address_id,
                                    header: "Create New Address",
                                    fields: [
                                        { label: "Address", name: "address", type: "text", required: true },
                                        { label: "City", name: "city", type: "text", required: true },
                                        { label: "State", md: "4", name: "state", type: "text", required: true },
                                        {
                                            label: "Pin Code",
                                            md: "4",
                                            name: "pincode",
                                            type: "number",
                                            required: true,
                                            validation: { minLength: 6, maxLength: 6, pattern: "^\\d+$" },
                                        },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}
                    {formCount == 3 ? (
                        <FormWidget
                            direction="column"
                            header="Add Products"
                            submitButtonLabel="continue →"
                            defaultValues={productFormData}
                            Onsubmit={nextCounter}
                            Onchange={watchProductFormChange}
                            fieldsets={[
                                {
                                    direction: "row",
                                    group: "products",
                                    additiontitle: "+ Add Product",
                                    header: "Add items",
                                    delete: true,
                                    grid: 3,
                                    count: 1,
                                    fields: [
                                        {
                                            label: "Select Product",
                                            name: "product_variant_id",
                                            type: "select",
                                            required: true,
                                            options: productVariantListData,
                                        },
                                        {
                                            label: "Quantity",
                                            name: "quantity",
                                            type: "number",
                                            required: true,
                                            validation: { min: 1, pattern: "^\\d+$" },
                                        },
                                        { label: "Price/Unit", name: "price", type: "display", prefix: "₹" },
                                        { label: "Total Price", name: "product_total", type: "display", prefix: "₹" },
                                    ],
                                },
                                {
                                    direction: "row",
                                    fields: [
                                        { label: "Order Total", name: "order_total", type: "display", prefix: "₹" },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}
                    {formCount == 4 ? (
                        <PMGrid container={true} xs={6}>
                            {formCount == 4 ? (
                                <DetailWidget
                                    direction="row"
                                    xs={12}
                                    justifyContent="center"
                                    fields={[
                                        { label: "Customer Name", value: formDataState?.placeholder_name },
                                        { label: "Email", value: formDataState?.placeholder_email },
                                        { label: "Phone", value: formDataState?.placeholder_phone },
                                    ]}
                                ></DetailWidget>
                            ) : (
                                ""
                            )}
                            {formCount == 4 ? (
                                <DetailWidget
                                    direction="row"
                                    xs={12}
                                    justifyContent="center"
                                    fields={[
                                        { label: "Payment Address", value: PaymentformState?.placeholder_address },
                                        { label: "Payment City", value: PaymentformState?.placeholder_city },
                                        { label: "Payment State", value: PaymentformState?.placeholder_state },
                                        { label: "Payment Pincode", value: PaymentformState?.placeholder_pincode },
                                    ]}
                                ></DetailWidget>
                            ) : (
                                ""
                            )}
                            {formCount == 4 ? (
                                <DetailWidget
                                    direction="row"
                                    justifyContent="center"
                                    xs={12}
                                    fields={[
                                        { label: "Shipment Address", value: shipmentformState?.placeholder_address },
                                        { label: "Shipment City", value: shipmentformState?.placeholder_city },
                                        { label: "Shipment State", value: shipmentformState?.placeholder_state },
                                        { label: "Shipment Pincode", value: shipmentformState?.placeholder_pincode },
                                    ]}
                                ></DetailWidget>
                            ) : (
                                ""
                            )}
                        </PMGrid>
                    ) : (
                        ""
                    )}
                    {formCount == 4 ? (
                        <PMGrid container={true} xs={6}>
                            <TableWidget
                                showSerialNumber={false}
                                showQuickFilter={false}
                                hideFooter={true}
                                xs={12}
                                rows={productFormData?.products || []}
                                columns={[
                                    { field: "product_name", headerName: "Product" },
                                    { field: "quantity", headerName: "Quantity" },
                                    { field: "price", headerName: "Price", type: "currency" },
                                    { field: "product_total", headerName: "Total", type: "currency" },
                                ]}
                            ></TableWidget>
                        </PMGrid>
                    ) : (
                        ""
                    )}
                    {formCount == 4 ? (
                        <FormWidget
                            direction="column"
                            header="Add Products"
                            submitButtonLabel="Create Order"
                            Onsubmit={createOrderApi}
                            Onchange={watchReviewFormChange}
                            fieldsets={[
                                {
                                    direction: "row",
                                    header: "Order Status",
                                    fields: [
                                        {
                                            label: "Shipping Method",
                                            md: "4",
                                            name: "shipping_method",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: "FREE_SHIPPING", label: "Free Shipping" },
                                                { id: "FLAT_30_RS_SHIPMENT", label: "Flat 30 Rs Shipment" },
                                            ],
                                        },
                                        { label: "Shipment Tracking ID", md: "4", name: "tracking_id", type: "text" },
                                        {
                                            label: "Payment Date",
                                            md: "4",
                                            name: "payment_date",
                                            type: "date",
                                            required: true,
                                        },
                                        {
                                            label: "Payment Method",
                                            md: "4",
                                            name: "payment_method",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: "RAZORPAY", label: "Razorpay" },
                                                { id: "PAYTM", label: "Paytm" },
                                                { id: "CREDITPERIOD", label: "Creditperiod" },
                                                { id: "FREEORDER", label: "Freeorder" },
                                                { id: "CASH", label: "Cash" },
                                                { id: "CREDITCARD", label: "Creditcard" },
                                                { id: "DEBITCARD", label: "Debitcard" },
                                                { id: "NETBANKING", label: "Netbanking" },
                                            ],
                                        },
                                        {
                                            label: "Order Status",
                                            md: "4",
                                            name: "order_status",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: "OPEN", label: "Open" },
                                                { id: "PENDING", label: "Pending" },
                                                { id: "PROCESSED", label: "Processed" },
                                                { id: "SHIPPED", label: "Shipped" },
                                                { id: "COMPLETE", label: "Complete" },
                                                { id: "CANCELLED", label: "Cancelled" },
                                                { id: "EXPIRED", label: "Expired" },
                                                { id: "MISSINGORDER", label: "Missingorder" },
                                                { id: "RETURNED", label: "Returned" },
                                                { id: "FAILED", label: "Failed" },
                                                { id: "REVERSED", label: "Reversed" },
                                            ],
                                        },
                                        { label: "Invoice No", md: "4", name: "invoice_number", type: "text" },
                                        { label: "Upload Order Invoice", md: "4", name: "invoice_file", type: "file" },
                                        { label: "Add Notes", md: "4", name: "notes", type: "text" },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default OrderCreate;
