const customerListFormatter = (data, dataKey) => {
    const returnData = {};
    const dataSet = data?.items ? JSON.parse(JSON.stringify(data?.items)) : [];
    dataSet.forEach((element) => {
        element["label"] = element["name"];
    });
    returnData[dataKey] = [{"id": "", "label": "--"}, ...dataSet];
    return returnData;
};

export default customerListFormatter;