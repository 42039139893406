// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, PMText, DetailWidget, FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useRecievedItemQuery } from "../store/recievedItemSlice";

import { useQualityFilesMutation } from "../store/qualityFilesSlice";
import { jsonToFormData } from "../helperFn/formData";
import { default as qualityFilesFormatter } from "../transformations/qualityFilesFormatter";
import { useQualityCheckMutation } from "../store/qualityCheckSlice";
import { default as qualityCheckFormatter } from "../transformations/qualityCheckFormatter";
import { default as recievedItemFormatter } from "../transformations/recievedItemFormatter";
function ShipmentQualityItem() {
    const recievedItemData = useSelector((states) => states?.appStore?.recievedItemData);
    const [recievedItemSkip, setrecievedItemSkip] = useState(true);
    const [recievedItemParams, setrecievedItemParams] = useState({});
    const recievedItemQuery = useRecievedItemQuery(recievedItemParams, { skip: recievedItemSkip });
    const qualityFilesMutation = useQualityFilesMutation();
    const qualityCheckMutation = useQualityCheckMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [formDataState, setformDataState] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const qcFileUpdated = (data) => {
        const responseData = data?.data || data;
        setformDataState(responseData);
    };

    const watchFormChange = (data) => {
        qcFileUpdated(data);
    };

    const qcdonenav = (data) => {
        history("/shipment/qualitylist/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Stock Updated", { variant: "success" });
    };

    const qualityFiles = async (data) => {
        var apiData = qualityFilesFormatter(data, formDataState);

        if (apiData["defective_images"] && apiData["defective_images"].length) {
            apiData["defective_images"].forEach(async (apiData) => {
                if (apiData) {
                    await qualityFilesMutation[0](
                        jsonToFormData({ qualitycheck_id: apiData["qualitycheck_id"], document: apiData["document"] })
                    ).unwrap();
                }
            });
        }
    };

    const qualityFilesSucess = (data) => {
        undefined(data);
    };
    useEffect(() => {
        if (qualityFilesMutation[1].isSuccess) {
            qualityFilesSucess(qualityFilesMutation[1]);
        }
        if (qualityFilesMutation[1].isError) {
            enqueueSnackbar(errorSerializer(qualityFilesMutation[1]), { variant: "error" });
        }
    }, [qualityFilesMutation]);

    const qualityCheck = async (data) => {
        var apiData = qualityCheckFormatter(data);

        if (apiData) {
            await qualityCheckMutation[0]({
                received_item_id: apiData["received_item_id"],
                leakage: apiData["leakage"],
                blooted: apiData["blooted"],
                other: apiData["other"],
                note: apiData["note"],
                date: apiData["date"],
            }).unwrap();
        }
    };

    const qualityCheckSucess = (data) => {
        qualityFiles(data);
        showSuccessAlert(data);
        qcdonenav(data);
    };
    useEffect(() => {
        if (qualityCheckMutation[1].isSuccess) {
            qualityCheckSucess(qualityCheckMutation[1]);
        }
        if (qualityCheckMutation[1].isError) {
            enqueueSnackbar(errorSerializer(qualityCheckMutation[1]), { variant: "error" });
        }
    }, [qualityCheckMutation]);

    const qualityCheckApi = (data) => {
        qualityCheck(data);
    };

    const saverecievedItemData = (data) => {
        var formatedData = {
            ...recievedItemFormatter(data?.data || data, "recievedItemData"),
        };
        dispatch(setStore(formatedData));
    };

    const recievedItemSuccess = (data) => {
        saverecievedItemData(data);
    };
    useEffect(() => {
        if (recievedItemQuery.isSuccess) {
            recievedItemSuccess(recievedItemQuery);
        } else if (recievedItemQuery.isError) {
            recievedItemSuccess(null);
        }
    }, [recievedItemQuery]);
    const recievedItem = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setrecievedItemParams({ id: id });
        setrecievedItemSkip(false);
    };

    useEffect(() => {
        recievedItem();
    }, [id]);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} xs={12}>
                    <PMGrid>
                        <PMText children="Create Stock Entry" variant="h6"></PMText>
                    </PMGrid>

                    <DetailWidget
                        direction="row"
                        header="Shipment Information"
                        md={12}
                        data={recievedItemData || []}
                        fields={[
                            { label: "Product name", key: "product_name" },
                            { label: "Batch No", key: "batch_no" },
                            { label: "Received Quantity(ea)", key: "quantity" },
                            { label: "Number of Cartons(ea)", key: "no_of_mono_carton" },
                            { label: "Shipment Date", key: "shipment_date" },
                        ]}
                    ></DetailWidget>

                    <FormWidget
                        direction="column"
                        header="Defective Packages(ea)"
                        defaultValues={recievedItemData || []}
                        Onsubmit={qualityCheckApi}
                        Onchange={watchFormChange}
                        fieldsets={[
                            {
                                direction: "row",
                                fields: [
                                    { label: "id", name: "received_item_id", type: "hidden" },
                                    {
                                        label: "Leakage",
                                        name: "leakage",
                                        type: "number",
                                        validation: { min: 1, pattern: "^\\d+$" },
                                    },
                                    {
                                        label: "Bloating",
                                        name: "blooted",
                                        type: "number",
                                        validation: { min: 1, pattern: "^\\d+$" },
                                    },
                                    {
                                        label: "Others",
                                        name: "other",
                                        type: "number",
                                        validation: { min: 1, pattern: "^\\d+$" },
                                    },
                                    { label: "QC Date", name: "date", type: "date", required: true },
                                    { label: "Notes", name: "note", type: "text" },
                                ],
                            },
                            {
                                direction: "row",
                                group: "defective_images",
                                additiontitle: "+ Add Images",
                                count: 1,
                                delete: true,
                                header: "Upload Defective Packet Images",
                                fields: [{ label: "Defective Packet Image", name: "document", type: "file" }],
                            },
                        ]}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ShipmentQualityItem;
