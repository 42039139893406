import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Menu from '@mui/icons-material/Menu';
import { Avatar, Button, Collapse, Popover, Stack } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from '@mui/material/Typography';
import * as React from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PMGrid, PMIcon } from "../../pmcomponents";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));



export default function PMMenu(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [subMenuOpen, setSubMenuOpen] = React.useState({});
    const location = useLocation();
    const params = useParams<Record<string, string>>();
    const history = useNavigate();

    const currentPath = Object.values(params).reduce(
        (path, param) => path.replace('/' + param, ''),
        location.pathname,
    );

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const profilePopOpen = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const userId = localStorage.getItem('userId')
    const userName = localStorage.getItem('userName')
    const email = localStorage.getItem('email')
    const phone = localStorage.getItem('phone')

    React.useEffect(() => {
        if(props?.menuItems){
            selectedUrl(props?.menuItems)
        }
    }, [props?.menuItems])

    const logOut = () => {
        localStorage.clear()
        history("/login")
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleClick = (url: string) => {
        if (props.onClick) {
            props.onClick({ url: url });
        }
    };

    const expandMenu = (index) => {
        let subMenuOpenTemp = {...subMenuOpen}
        if(subMenuOpenTemp[index]){
            subMenuOpenTemp[index] = !subMenuOpenTemp[index]
        }else{
            subMenuOpenTemp[index] = true
        }
        setSubMenuOpen({...subMenuOpenTemp})
    }

    const selectedUrl = (menuData, parentIndex='') => {
        menuData?.map((item, index) => {
            if(item?.url && currentPath===item.url){
                if(parentIndex){
                    if(!subMenuOpen[parentIndex]){
                        expandMenu(parentIndex)
                    }
                }
            }
            if(item.collapseItem){
                selectedUrl(item.collapseItem, index)
            }
        })
    }

    const stringToColor = (string: string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    const stringAvatar = (name: string) => {
        if(!name){
            return {}
        }
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name[0].toUpperCase()}`,
        };
      }

    const menuItemLayout = (items, isChild=false, parentIndex=0) => {
        return (
            <List>
                {items?.map((item, index) => {
                    let key = "li" + index;
                    let selected = false
                    if(item?.url && currentPath===item.url){
                        selected = true
                    }
                    switch (item?.type) {
                        case "Divider":
                            return <Divider key={key}/>;
                        case "Subheader":
                            return <ListSubheader component="div" key={key}>{item.text}</ListSubheader>;
                        case "Nested":
                            return (
                                <>
                                    <ListItem onClick={() => expandMenu(index)} key={key} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <PMIcon icon={item.icon.icon}></PMIcon>
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                            {subMenuOpen[index] ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </ListItem>
                                    <Collapse in={subMenuOpen[index]} timeout="auto">
                                        {menuItemLayout(item?.collapseItem, true, index)}
                                    </Collapse>
                                </>
                            );
                        default:
                            return (
                                <ListItem
                                    sx={selected?{color: 'primary.main', borderColor: 'primary.main', borderLeft: 3}: {}}
                                    onClick={() => handleClick(item?.url)} key={key}  disablePadding>
                                    <ListItemButton sx={isChild?{pl:4}:{}}>
                                        <ListItemIcon>
                                            <PMIcon icon={item.icon.icon} color={selected?'primary': 'inherit'}></PMIcon>
                                        </ListItemIcon>
                                        <ListItemText primary={item.text} />
                                    </ListItemButton>
                                </ListItem>
                            );
                    }
                })}
            </List>
        )
    }

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                        <Menu />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                        >
                            {props?.title || ''}
                    </Typography>

                    {/* <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton> */}

                    <PMGrid alignItems={"right"} grid={12}>
                    <IconButton onClick={handleProfileClick} sx={{ p: 0 }}>
                        <Avatar {...stringAvatar(userName)} alt={userName || 'A'} />
                    </IconButton>
                         <Popover
                            id={id}
                            open={profilePopOpen}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            sx={{p:2}}
                        >
                            <Stack sx={{p:2}} justifyContent='right'>
                                <Typography variant="h6">{userName || ""}</Typography>
                                <Typography sx={{py:1}} variant="caption">{email || ""}</Typography>

                                <Button variant='contained' size='small' onClick={logOut}>Log Out</Button>
                            </Stack>
                        </Popover>
                    </PMGrid>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <Toolbar>
                        <img src={props.logo} style={{ maxWidth: "100%", maxHeight: "100%" }}></img>
                        {/* <img src="logo.png" alt="logo" className={classes.logo} /> */}
                    </Toolbar>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                {menuItemLayout(props?.menuItems)}
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {props?.children}
            </Main>
        </Box>
    );
}
