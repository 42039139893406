const qualityCheckFormatter = (data) => {
    const dataSet = data?.data || data;
    dataSet["leakage"] =  dataSet["leakage"]? dataSet["leakage"]: null
    dataSet["blooted"] =  dataSet["blooted"]? dataSet["blooted"]: null
    dataSet["other"] =  dataSet["other"]? dataSet["other"]: null
    dataSet["note"] =  dataSet["note"]? dataSet["note"]: null
    dataSet["date"] =  dataSet["date"]? dataSet["date"]: null

    return dataSet
};

export default qualityCheckFormatter;