const productVariantDetailFormatter = (data, datakey) => {
    const dataSet = {} 
    dataSet[datakey] = JSON.parse(JSON.stringify(data?.data || data));
    dataSet[datakey]["product_name"] = dataSet[datakey]["product"]["name"]
    dataSet[datakey]["available"] = dataSet[datakey]["stock"]["available"] || 0;
    dataSet[datakey]["pipeline"] = dataSet[datakey]["stock"]["pipeline"] || 0;
    dataSet[datakey]["locked_by_order"] = dataSet[datakey]["stock"]["locked_by_order"] || 0;
// returnData[datakey] = dataSet["items"]
    return dataSet
};
export default productVariantDetailFormatter;