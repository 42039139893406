const qualityCheckFormatter = (data, productList, pricingList) => {
    const dataSet = data?.data || data;
    var total = 0
    dataSet["products"].forEach((element, index) => {
        const products = productList.filter((ele) => ele.id==element.product_variant_id)[0]
        const price = pricingList["items"].filter((ele) => ele.product_variant_id==element.product_variant_id)[0]
        element["product_name"] = products["product"]["name"]
        element["price"] = price["price"]
        if(element["quantity"]){
            element["product_total"] = parseInt(element["quantity"])*parseInt(price["price"])
            total += element["product_total"]
        }
    });
    dataSet["order_total"] = total
    return dataSet
};

export default qualityCheckFormatter;