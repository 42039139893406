// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useRecievedItemListQuery } from "../store/recievedItemListSlice";
import { default as recievedItemListFormatter } from "../transformations/recievedItemListFormatter";
function ShipmentQuality() {
    const [recievedItemListSkip, setrecievedItemListSkip] = useState(true);
    const [recievedItemListParams, setrecievedItemListParams] = useState({});
    const recievedItemListQuery = useRecievedItemListQuery(recievedItemListParams, { skip: recievedItemListSkip });
    const dispatch = useDispatch();
    const recievedItemListData = useSelector((states) => states?.appStore?.recievedItemListData);

    const recievedItemListSuccess = (data) => {
        saverecievedItemListData(data);
    };
    useEffect(() => {
        if (recievedItemListQuery.isSuccess) {
            recievedItemListSuccess(recievedItemListQuery);
        } else if (recievedItemListQuery.isError) {
            recievedItemListSuccess(null);
        }
    }, [recievedItemListQuery]);
    const recievedItemList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setrecievedItemListParams({
            received_item_status: data ? data["Received"] : "Received",
            order_by: data ? data["-created_at"] : "-created_at",
        });
        setrecievedItemListSkip(false);
    };

    const saverecievedItemListData = (data) => {
        var formatedData = {
            ...recievedItemListFormatter(data?.data || data, "recievedItemListData"),
        };
        dispatch(setStore(formatedData));
    };

    useEffect(() => {
        recievedItemList();
    }, []);

    const recievedItemListReFetch = (tableParams) => {
        setrecievedItemListParams({ received_item_status: "Received", order_by: "-created_at", ...tableParams });
    };
    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Inward Stocks Against Shipment"
                        showSerialNumber={true}
                        xs={12}
                        tableApi={recievedItemListData || []}
                        updateTable={recievedItemListReFetch}
                        columns={[
                            { field: "shipment_date", headerName: "Delivery Date", type: "date" },
                            { field: "po_number", headerName: "PO Number", highlight: true, sortable: false },
                            { field: "product_name", headerName: "Product Name", highlight: true, sortable: false },
                            { field: "batch_no", headerName: "Batch No" },
                            { field: "quantity", headerName: "Qty Received (e.a)" },
                            { field: "no_of_mono_carton", headerName: "No Of Cartons" },
                            { field: "grn_file", headerName: "Download GRN", type: "download", sortable: false },
                            { field: "Inward Stock", headerName: "Inward Stock", type: "edit", sortable: false },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ShipmentQuality;
