const recievedItemListFormatter = (data, dataKey) => {
    const dataSet = {};
    dataSet[dataKey] = JSON.parse(JSON.stringify(data));
    dataSet[dataKey]["items"].forEach((element) => {
        element["product_name"] = element["poitem"]["productvariant"]["product"]["name"]
        element["po_number"] = element["poitem"]["purchaseorder"]["po_number"]
    });

    return dataSet;
};

export default recievedItemListFormatter;