const manufacturerDropDown = (data, dataKey) => {
    const dataSet = {};
    dataSet[dataKey] = data?.items ? JSON.parse(JSON.stringify(data?.items)) : [];
    dataSet[dataKey].forEach((element) => {
        element["label"] = element["name"];
    });

    return dataSet;
};

export default manufacturerDropDown;