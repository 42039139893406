// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, MetricWidget, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useCurrentStockQuery } from "../store/currentStockSlice";

import { useProductVariantListQuery } from "../store/productVariantListSlice";
import { default as currentStockFormatter } from "../transformations/currentStockFormatter";
import { default as productVariantListFormatter } from "../transformations/productVariantListFormatter";
function Home() {
    const currentStockData = useSelector((states) => states?.appStore?.currentStockData);
    const productVariantListData = useSelector((states) => states?.appStore?.productVariantListData);
    const [currentStockSkip, setcurrentStockSkip] = useState(true);
    const [currentStockParams, setcurrentStockParams] = useState({});
    const currentStockQuery = useCurrentStockQuery(currentStockParams, { skip: currentStockSkip });
    const [productVariantListSkip, setproductVariantListSkip] = useState(true);
    const [productVariantListParams, setproductVariantListParams] = useState({});
    const productVariantListQuery = useProductVariantListQuery(productVariantListParams, {
        skip: productVariantListSkip,
    });
    const dispatch = useDispatch();

    const saveProductVariantListData = (data) => {
        var formatedData = {
            ...productVariantListFormatter(data?.data || data, "productVariantListData"),
        };
        dispatch(setStore(formatedData));
    };

    const productVariantListSuccess = (data) => {
        saveProductVariantListData(data);
    };
    useEffect(() => {
        if (productVariantListQuery.isSuccess) {
            productVariantListSuccess(productVariantListQuery);
        } else if (productVariantListQuery.isError) {
            productVariantListSuccess(null);
        }
    }, [productVariantListQuery]);
    const productVariantList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setproductVariantListSkip(false);
    };

    const currentStockListData = (data) => {
        var formatedData = {
            ...currentStockFormatter(data?.data || data, "currentStockData"),
        };
        dispatch(setStore(formatedData));
    };

    const currentStockSuccess = (data) => {
        currentStockListData(data);
    };
    useEffect(() => {
        if (currentStockQuery.isSuccess) {
            currentStockSuccess(currentStockQuery);
        } else if (currentStockQuery.isError) {
            currentStockSuccess(null);
        }
    }, [currentStockQuery]);
    const currentStock = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcurrentStockSkip(false);
    };

    useEffect(() => {
        currentStock();
        productVariantList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} marginTop={2}>
                    <MetricWidget
                        xs={3}
                        metricIcon="inventory"
                        header="Total Products"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        metricValue={currentStockData?.total || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="trending_down"
                        metricValueColor="warning.main"
                        metricIconColor="warning"
                        header="Low Stock Products"
                        metricValue={currentStockData?.low_stock || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="layers_clear"
                        metricValueColor="error"
                        metricIconColor="error"
                        header="Out of Stock Products"
                        metricValue={currentStockData?.no_stock || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="domain_verification"
                        metricValueColor="success.main"
                        metricIconColor="success"
                        header="Well Stocked Products"
                        metricValue={currentStockData?.high_stock || []}
                    ></MetricWidget>
                </PMGrid>

                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Current Inventory"
                        showSerialNumber={true}
                        rowColor={[
                            { rule: 0, color: "error" },
                            { rule: [1, 100], color: "warning" },
                        ]}
                        rowColorField="available"
                        xs={12}
                        rows={productVariantListData || []}
                        columns={[
                            { field: "product_name", headerName: "Product" },
                            { field: "available", headerName: "Available Stock" },
                            { field: "pipeline", headerName: "Pipeline" },
                            { field: "locked_by_order", headerName: "Locked By Order" },
                            { field: "shipped", headerName: "Shipped" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Home;
