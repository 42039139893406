const shipmentFormFormatter = (data, addressdata, paymentFormData) => {
    const dataSet = data;
    if(dataSet["shipment_address_id"]){
        const payment_data = addressdata.filter((ele) => ele.id==dataSet["shipment_address_id"])[0]
        dataSet["placeholder_address"] = payment_data["address"]
        dataSet["placeholder_city"] = payment_data["city"]
        dataSet["placeholder_state"] = payment_data["state"]
        dataSet["placeholder_pincode"] = payment_data["pincode"]
        dataSet["address"] = null
        dataSet["city"] = null
        dataSet["state"] = null
        dataSet["pincode"] = null
    }else{
        dataSet["placeholder_address"] = null
        dataSet["placeholder_city"] = null
        dataSet["placeholder_state"] = null
        dataSet["placeholder_pincode"] = null
    }
    if(dataSet["same_address"]){
        dataSet["shipment_address_id"] = paymentFormData["payment_address_id"]
        dataSet["placeholder_address"] = paymentFormData["placeholder_address"]
        dataSet["placeholder_city"] = paymentFormData["placeholder_city"]
        dataSet["placeholder_state"] = paymentFormData["placeholder_state"]
        dataSet["placeholder_pincode"] = paymentFormData["placeholder_pincode"]
        dataSet["address"] = null
        dataSet["city"] = null
        dataSet["state"] = null
        dataSet["pincode"] = null

    }
    return dataSet;
};

export default shipmentFormFormatter;  