const saddressUpdateFormatter = (data, currentForm) => {
    const dataSet = data?.data || data;
    if(dataSet["id"]){
        currentForm["shipment_address_id"] = dataSet["id"]
        currentForm["placeholder_address"] = dataSet["address"]
        currentForm["placeholder_city"] = dataSet["city"]
        currentForm["placeholder_state"] = dataSet["state"]
        currentForm["placeholder_pincode"] = dataSet["pincode"]
    }
    return currentForm;
};

export default saddressUpdateFormatter;  