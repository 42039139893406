import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const createpoSlice = createApi({
    reducerPath: "createpo",
    baseQuery: fetchBaseQuery({
        baseUrl: "http://order.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        createpo: builder.mutation({
            query: (data) => ({ url: "/inventory/api/v1/purchaseorder/create", method: "POST", body: data }),
        }),
    }),
});

export const { useCreatepoMutation } = createpoSlice;
