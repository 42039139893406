
const productFormSubmitFormatter = (data, defaultFormData) => {
    const dataSet = data?.data || data;
    const formData = JSON.parse(JSON.stringify(defaultFormData))
    formData["products"].forEach((element) => {
        element["order_id"] = dataSet["id"]
    })
    return formData;
};

export default productFormSubmitFormatter;