import _ from "lodash";

const orderCreateFormatter = (data, defaultFormData) => {
    const dataSet = data?.data || data;
    const formData = JSON.parse(JSON.stringify(defaultFormData))
    const orderId = formData["id"]
    const returnData = _.merge({}, formData, dataSet) 
    if(orderId){
        returnData["id"] = orderId
    }
    return returnData;
};

export default orderCreateFormatter;