const OrderformDataFormatter = (data, cutomerdata, emailData, phoneData) => {
    const dataSet = data;
    if(dataSet["customer_id"]){
        const customer_data = cutomerdata?.filter((ele) => ele.id==dataSet["customer_id"])[0]
        dataSet["placeholder_name"] = customer_data["name"]
        dataSet["name"] = null
    }else{
        dataSet["placeholder_name"] = null
    }
    if(dataSet["email_id"] && emailData){
        const customer_data = emailData?.filter((ele) => ele.id==dataSet["email_id"])[0]
        dataSet["placeholder_email"] = customer_data["email_address"]
    }else{
        dataSet["placeholder_email"] = null
    }
    if(dataSet["phone_id"] && phoneData){
        const customer_data = phoneData?.filter((ele) => ele.id==dataSet["phone_id"])[0]
        dataSet["placeholder_phone"] = customer_data["phone_number"]
    }else{
        dataSet["placeholder_phone"] = null
    }
    return dataSet;
};

export default OrderformDataFormatter;  