// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, PMStepper, PMButton, FormWidget, DetailWidget, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useOrderDetailsQuery } from "../store/orderDetailsSlice";

import { useShipmentListQuery } from "../store/shipmentListSlice";

import { usePaymentListQuery } from "../store/paymentListSlice";

import { useOrderItemListQuery } from "../store/orderItemListSlice";

import { useCustomerListQuery } from "../store/customerListSlice";

import { useProductVariantListQuery } from "../store/productVariantListSlice";

import { useCustomerEmailCreateMutation } from "../store/customerEmailCreateSlice";
import { default as emailCreateFormatter } from "../transformations/emailCreateFormatter";
import { useCustomerPhoneCreateMutation } from "../store/customerPhoneCreateSlice";
import { default as phoneCreateFormatter } from "../transformations/phoneCreateFormatter";
import { useCustomerCreateMutation } from "../store/customerCreateSlice";

import { useCustomerAddressListQuery } from "../store/customerAddressListSlice";

import { usePricingListQuery } from "../store/pricingListSlice";

import { useCustomerEmailListQuery } from "../store/customerEmailListSlice";

import { useCustomerPhoneListQuery } from "../store/customerPhoneListSlice";

import { useCustomerAddressCreateMutation } from "../store/customerAddressCreateSlice";
import { default as AddressCreateFormatter } from "../transformations/AddressCreateFormatter";
import { useShipAddressCreateMutation } from "../store/shipAddressCreateSlice";
import { default as shipAddressCreateFormatter } from "../transformations/shipAddressCreateFormatter";
import { useShipmentUpdateMutation } from "../store/shipmentUpdateSlice";
import { default as shipmentCreateFormatter } from "../transformations/shipmentCreateFormatter";
import { usePaymentUpdateMutation } from "../store/paymentUpdateSlice";
import { default as paymentCreateFormatter } from "../transformations/paymentCreateFormatter";
import { useOrderItemUpdateMutation } from "../store/orderItemUpdateSlice";

import { useOrderItemCreateMutation } from "../store/orderItemCreateSlice";
import { default as productFormSubmitFormatter } from "../transformations/productFormSubmitFormatter";
import { useNotesCreateMutation } from "../store/notesCreateSlice";
import { default as notesCreateFormatter } from "../transformations/notesCreateFormatter";
import { useOrderUpdateMutation } from "../store/orderUpdateSlice";
import { jsonToFormData } from "../helperFn/formData";
import { default as orderCreateFormatter } from "../transformations/orderCreateFormatter";
import { default as customerListFormatter } from "../transformations/customerListFormatter";
import { default as productVariantListFormatter } from "../transformations/productVariantListFormatter";
import { default as customerAddressListFormatter } from "../transformations/customerAddressListFormatter";
import { default as customerEmailListFormatter } from "../transformations/customerEmailListFormatter";
import { default as customerPhoneListFormatter } from "../transformations/customerPhoneListFormatter";
import { default as customerAddressUpdateFormatter } from "../transformations/customerAddressUpdateFormatter";
import { default as orderDetailsFormatter } from "../transformations/orderDetailsFormatter";
import { default as shipmentListFormatter } from "../transformations/shipmentListFormatter";
import { default as paymentListFormatter } from "../transformations/paymentListFormatter";
import { default as orderItemListFormatter } from "../transformations/orderItemListFormatter";
import { default as emailDataUpdateFormatter } from "../transformations/emailDataUpdateFormatter";
import { default as phoneDataUpdateFormatter } from "../transformations/phoneDataUpdateFormatter";
import { default as customerDataUpdateFormatter } from "../transformations/customerDataUpdateFormatter";
import { default as OrderformDataFormatter } from "../transformations/OrderformDataFormatter";
import { default as paddressUpdateFormatter } from "../transformations/paddressUpdateFormatter";
import { default as paymentFormFormatter } from "../transformations/paymentFormFormatter";
import { default as saddressUpdateFormatter } from "../transformations/saddressUpdateFormatter";
import { default as shipmentFormFormatter } from "../transformations/shipmentFormFormatter";
import { default as orderReviewFormatter } from "../transformations/orderReviewFormatter";
import { default as productFormFormatter } from "../transformations/productFormFormatter";
function OrderUpdate() {
    const [orderDetailsSkip, setorderDetailsSkip] = useState(true);
    const [orderDetailsParams, setorderDetailsParams] = useState({});
    const orderDetailsQuery = useOrderDetailsQuery(orderDetailsParams, { skip: orderDetailsSkip });
    const [shipmentListSkip, setshipmentListSkip] = useState(true);
    const [shipmentListParams, setshipmentListParams] = useState({});
    const shipmentListQuery = useShipmentListQuery(shipmentListParams, { skip: shipmentListSkip });
    const [paymentListSkip, setpaymentListSkip] = useState(true);
    const [paymentListParams, setpaymentListParams] = useState({});
    const paymentListQuery = usePaymentListQuery(paymentListParams, { skip: paymentListSkip });
    const [orderItemListSkip, setorderItemListSkip] = useState(true);
    const [orderItemListParams, setorderItemListParams] = useState({});
    const orderItemListQuery = useOrderItemListQuery(orderItemListParams, { skip: orderItemListSkip });
    const [customerListSkip, setcustomerListSkip] = useState(true);
    const [customerListParams, setcustomerListParams] = useState({});
    const customerListQuery = useCustomerListQuery(customerListParams, { skip: customerListSkip });
    const [productVariantListSkip, setproductVariantListSkip] = useState(true);
    const [productVariantListParams, setproductVariantListParams] = useState({});
    const productVariantListQuery = useProductVariantListQuery(productVariantListParams, {
        skip: productVariantListSkip,
    });
    const customerEmailCreateMutation = useCustomerEmailCreateMutation();
    const customerPhoneCreateMutation = useCustomerPhoneCreateMutation();
    const customerCreateMutation = useCustomerCreateMutation();
    const [customerAddressListSkip, setcustomerAddressListSkip] = useState(true);
    const [customerAddressListParams, setcustomerAddressListParams] = useState({});
    const customerAddressListQuery = useCustomerAddressListQuery(customerAddressListParams, {
        skip: customerAddressListSkip,
    });
    const [pricingListSkip, setpricingListSkip] = useState(true);
    const [pricingListParams, setpricingListParams] = useState({});
    const pricingListQuery = usePricingListQuery(pricingListParams, { skip: pricingListSkip });
    const [customerEmailListSkip, setcustomerEmailListSkip] = useState(true);
    const [customerEmailListParams, setcustomerEmailListParams] = useState({});
    const customerEmailListQuery = useCustomerEmailListQuery(customerEmailListParams, { skip: customerEmailListSkip });
    const [customerPhoneListSkip, setcustomerPhoneListSkip] = useState(true);
    const [customerPhoneListParams, setcustomerPhoneListParams] = useState({});
    const customerPhoneListQuery = useCustomerPhoneListQuery(customerPhoneListParams, { skip: customerPhoneListSkip });
    const customerAddressCreateMutation = useCustomerAddressCreateMutation();
    const shipAddressCreateMutation = useShipAddressCreateMutation();
    const shipmentUpdateMutation = useShipmentUpdateMutation();
    const paymentUpdateMutation = usePaymentUpdateMutation();
    const orderItemCreateMutation = useOrderItemCreateMutation();
    const orderItemUpdateMutation = useOrderItemUpdateMutation();
    const notesCreateMutation = useNotesCreateMutation();
    const orderUpdateMutation = useOrderUpdateMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [formDataState, setformDataState] = useState([]);
    const [shipmentformState, setshipmentformState] = useState([]);
    const [PaymentformState, setPaymentformState] = useState([]);
    const [productFormData, setproductFormData] = useState([]);
    const [formCount, setformCount] = useState(0);
    const [pricingListData, setpricingListData] = useState([]);
    const [reviewFormData, setreviewFormData] = useState([]);
    const [reviewFormChangeData, setreviewFormChangeData] = useState([]);
    const customerListData = useSelector((states) => states?.appStore?.customerListData);
    const customerAddressListData = useSelector((states) => states?.appStore?.customerAddressListData);
    const productVariantListData = useSelector((states) => states?.appStore?.productVariantListData);
    const customerEmailListData = useSelector((states) => states?.appStore?.customerEmailListData);
    const customerPhoneListData = useSelector((states) => states?.appStore?.customerPhoneListData);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const reviewFormUpdate = (data) => {
        const responseData = data?.data || data;
        setreviewFormChangeData(responseData);
    };

    const watchReviewFormChange = (data) => {
        reviewFormUpdate(data);
    };

    const notesCreated = (data) => {
        var formatedData = {
            notesCreatedData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const notesCreate = async (data) => {
        var apiData = notesCreateFormatter(data, reviewFormChangeData);

        if (apiData && apiData["note"]) {
            await notesCreateMutation[0]({ order_id: apiData["id"], note: apiData["note"] }).unwrap();
        }
    };

    const notesCreateSuccess = (data) => {
        notesCreated(data);
    };
    useEffect(() => {
        if (notesCreateMutation[1].isSuccess) {
            notesCreateSuccess(notesCreateMutation[1]);
        }
        if (notesCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(notesCreateMutation[1]), { variant: "error" });
        }
    }, [notesCreateMutation]);

    const pocreated = (data) => {
        history("/orders/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Order Updated", { variant: "success" });
    };

    const orderItemUpdate = async (data) => {
        var apiData = productFormSubmitFormatter(data, productFormData);

        if (apiData["products"] && apiData["products"].length) {
            apiData["products"].forEach(async (apiData) => {
                if (apiData) {
                    if (apiData["id"]) {
                        await orderItemUpdateMutation[0]({
                            id: apiData["id"],
                            data: {
                                order_id: apiData["order_id"],
                                product_variant_id: apiData["product_variant_id"],
                                oreder_item_status: apiData["oreder_item_status"],
                                quantity: apiData["quantity"],
                                price: apiData["price"],
                            },
                        }).unwrap();
                    } else {
                        await orderItemCreateMutation[0]({
                            order_id: apiData["order_id"],
                            product_variant_id: apiData["product_variant_id"],
                            oreder_item_status: apiData["oreder_item_status"],
                            quantity: apiData["quantity"],
                            price: apiData["price"],
                        }).unwrap();
                    }
                }
            });
        }
    };

    const orderItemUpdateSuccess = (data) => {
        showSuccessAlert(data);
        pocreated(data);
    };
    useEffect(() => {
        if (orderItemUpdateMutation[1].isSuccess) {
            orderItemUpdateSuccess(orderItemUpdateMutation[1]);
        }
        if (orderItemUpdateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(orderItemUpdateMutation[1]), { variant: "error" });
        }
    }, [orderItemUpdateMutation]);

    const paymentUpdated = (data) => {
        var formatedData = {
            paymentUpdatedData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const paymentUpdate = async (data) => {
        var apiData = paymentCreateFormatter(data, formDataState, PaymentformState, reviewFormChangeData);

        if (apiData) {
            await paymentUpdateMutation[0]({
                id: apiData["id"],
                data: {
                    payment_address_id: apiData["payment_address_id"],
                    email_id: apiData["email_id"],
                    phone_id: apiData["phone_id"],
                    payment_mode: apiData["payment_mode"],
                    payment_date: apiData["payment_date"],
                },
            }).unwrap();
        }
    };

    const paymentUpdateSuccess = (data) => {
        paymentUpdated(data);
    };
    useEffect(() => {
        if (paymentUpdateMutation[1].isSuccess) {
            paymentUpdateSuccess(paymentUpdateMutation[1]);
        }
        if (paymentUpdateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(paymentUpdateMutation[1]), { variant: "error" });
        }
    }, [paymentUpdateMutation]);

    const shipmentUpdated = (data) => {
        var formatedData = {
            shipmentUpdatedData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const shipmentUpdate = async (data) => {
        var apiData = shipmentCreateFormatter(data, formDataState, shipmentformState, reviewFormChangeData);

        if (apiData) {
            await shipmentUpdateMutation[0]({
                id: apiData["id"],
                data: {
                    shipment_address_id: apiData["shipment_address_id"],
                    email_id: apiData["email_id"],
                    phone_id: apiData["phone_id"],
                    shipping_method: apiData["shipping_method"],
                    shipment_status: apiData["shipment_status"],
                    tracking_id: apiData["tracking_id"],
                },
            }).unwrap();
        }
    };

    const shipmentUpdateSuccess = (data) => {
        shipmentUpdated(data);
    };
    useEffect(() => {
        if (shipmentUpdateMutation[1].isSuccess) {
            shipmentUpdateSuccess(shipmentUpdateMutation[1]);
        }
        if (shipmentUpdateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(shipmentUpdateMutation[1]), { variant: "error" });
        }
    }, [shipmentUpdateMutation]);

    const orderUpdate = async (data) => {
        var apiData = orderCreateFormatter(data, formDataState);

        if (apiData) {
            await orderUpdateMutation[0]({
                id: apiData["id"],
                data: jsonToFormData({
                    channel: apiData["channel"],
                    order_status: apiData["order_status"],
                    phone_id: apiData["phone_id"],
                    invoice_file: apiData["file"],
                    invoice_number: apiData["invoice_number"],
                    customer_id: apiData["customer_id"],
                    email_id: apiData["email_id"],
                }),
            }).unwrap();
        }
    };

    const orderCreateSuccess = (data) => {
        shipmentUpdate(data);
        paymentUpdate(data);
        orderItemUpdate(data);
        notesCreate(data);
    };
    useEffect(() => {
        if (orderUpdateMutation[1].isSuccess) {
            orderCreateSuccess(orderUpdateMutation[1]);
        }
        if (orderUpdateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(orderUpdateMutation[1]), { variant: "error" });
        }
    }, [orderUpdateMutation]);

    const createOrderApi = (data) => {
        orderUpdate(data);
    };

    const productFormUpdate = (data) => {
        const responseData = data?.data || data;
        setproductFormData(productFormFormatter(responseData, productVariantListData, pricingListData));
    };

    const watchProductFormChange = (data) => {
        productFormUpdate(data);
    };

    const orderReviewData = (data) => {
        const responseData = data?.data || data;
        setreviewFormData(orderReviewFormatter(responseData, formDataState, shipmentformState, PaymentformState));
    };

    const customerAdded = () => {
        let formCountTemp = parseInt(formCount) + 1;
        setformCount(formCountTemp);
    };

    const nextCounter = (data) => {
        customerAdded(data);

        orderReviewData(data);
    };

    const shipmentFormUpdate = (data) => {
        const responseData = data?.data || data;
        setshipmentformState(shipmentFormFormatter(responseData, customerAddressListData, PaymentformState));
    };

    const watchShipmentFormChange = (data) => {
        shipmentFormUpdate(data);
    };

    const shipaddressDataUpdate = (data) => {
        const responseData = data?.data || data;
        setshipmentformState(saddressUpdateFormatter(responseData, shipmentformState));
    };

    const shipAddressCreate = async (data) => {
        var apiData = shipAddressCreateFormatter(data, formDataState);

        if (apiData && apiData["address"]) {
            await shipAddressCreateMutation[0]({
                city: apiData["city"],
                state: apiData["state"],
                pincode: apiData["pincode"],
                address: apiData["address"],
                customer_id: apiData["customer_id"],
            }).unwrap();
        }
    };

    const shipAddressSuccess = (data) => {
        shipaddressDataUpdate(data);
    };
    useEffect(() => {
        if (shipAddressCreateMutation[1].isSuccess) {
            shipAddressSuccess(shipAddressCreateMutation[1]);
        }
        if (shipAddressCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(shipAddressCreateMutation[1]), { variant: "error" });
        }
    }, [shipAddressCreateMutation]);

    const shipAddressCreateApi = (data) => {
        shipAddressCreate(data);

        customerAdded(data);
    };

    const paymentFormUpdate = (data) => {
        const responseData = data?.data || data;
        setPaymentformState(paymentFormFormatter(responseData, customerAddressListData));
    };

    const watchPaymentFormChange = (data) => {
        paymentFormUpdate(data);
    };

    const paddressDataUpdate = (data) => {
        const responseData = data?.data || data;
        setPaymentformState(paddressUpdateFormatter(responseData, PaymentformState, customerAddressListData));
    };

    const customerAddressUpdatedStore = (data) => {
        var formatedData = {
            ...customerAddressUpdateFormatter(data?.data || data, customerAddressListData, "customerAddressListData"),
        };
        dispatch(setStore(formatedData));
    };

    const customerAddressCreate = async (data) => {
        var apiData = AddressCreateFormatter(data, formDataState);

        if (apiData && apiData["address"]) {
            await customerAddressCreateMutation[0]({
                city: apiData["city"],
                state: apiData["state"],
                pincode: apiData["pincode"],
                address: apiData["address"],
                customer_id: apiData["customer_id"],
            }).unwrap();
        }
    };

    const customerAddressSuccess = (data) => {
        customerAddressUpdatedStore(data);
        paddressDataUpdate(data);
    };
    useEffect(() => {
        if (customerAddressCreateMutation[1].isSuccess) {
            customerAddressSuccess(customerAddressCreateMutation[1]);
        }
        if (customerAddressCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(customerAddressCreateMutation[1]), { variant: "error" });
        }
    }, [customerAddressCreateMutation]);

    const customerAddressCreateApi = (data) => {
        customerAddressCreate(data);

        customerAdded(data);
    };

    const formdataUpadte = (data) => {
        const responseData = data?.data || data;
        setformDataState(
            OrderformDataFormatter(responseData, customerListData, customerEmailListData, customerPhoneListData)
        );
    };

    const customerPhoneListStore = (data) => {
        var formatedData = {
            ...customerPhoneListFormatter(data?.data || data, "customerPhoneListData"),
        };
        dispatch(setStore(formatedData));
    };

    const customerPhoneSuccess = (data) => {
        customerPhoneListStore(data);
    };
    useEffect(() => {
        if (customerPhoneListQuery.isSuccess) {
            customerPhoneSuccess(customerPhoneListQuery);
        } else if (customerPhoneListQuery.isError) {
            customerPhoneSuccess(null);
        }
    }, [customerPhoneListQuery]);
    const customerPhoneList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcustomerPhoneListParams({ customer_id: data ? data["customer_id"] : "customer_id" });
        setcustomerPhoneListSkip(false);
    };

    const customerEmailListStore = (data) => {
        var formatedData = {
            ...customerEmailListFormatter(data?.data || data, "customerEmailListData"),
        };
        dispatch(setStore(formatedData));
    };

    const customerEmailSuccess = (data) => {
        customerEmailListStore(data);
    };
    useEffect(() => {
        if (customerEmailListQuery.isSuccess) {
            customerEmailSuccess(customerEmailListQuery);
        } else if (customerEmailListQuery.isError) {
            customerEmailSuccess(null);
        }
    }, [customerEmailListQuery]);
    const customerEmailList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcustomerEmailListParams({ customer_id: data ? data["customer_id"] : "customer_id" });
        setcustomerEmailListSkip(false);
    };

    const pricingListStore = (data) => {
        const responseData = data?.data || data;
        setpricingListData(responseData);
    };

    const pricingListSiuccess = (data) => {
        pricingListStore(data);
    };
    useEffect(() => {
        if (pricingListQuery.isSuccess) {
            pricingListSiuccess(pricingListQuery);
        } else if (pricingListQuery.isError) {
            pricingListSiuccess(null);
        }
    }, [pricingListQuery]);
    const pricingList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpricingListParams({ channel: data ? data["channel"] : "channel" });
        setpricingListSkip(false);
    };

    const customerAddressListStore = (data) => {
        var formatedData = {
            ...customerAddressListFormatter(data?.data || data, "customerAddressListData"),
        };
        dispatch(setStore(formatedData));
    };

    const manufacturerSuccess3 = (data) => {
        customerAddressListStore(data);
    };
    useEffect(() => {
        if (customerAddressListQuery.isSuccess) {
            manufacturerSuccess3(customerAddressListQuery);
        } else if (customerAddressListQuery.isError) {
            manufacturerSuccess3(null);
        }
    }, [customerAddressListQuery]);
    const customerAddressList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcustomerAddressListParams({ customer_id: data ? data["customer_id"] : "customer_id" });
        setcustomerAddressListSkip(false);
    };

    const watchFormChange = (customer_id) => {
        customerAddressList(customer_id);

        pricingList(customer_id);

        customerEmailList(customer_id);

        customerPhoneList(customer_id);

        formdataUpadte(customer_id);
    };

    const customerDataUpdate = (data) => {
        const responseData = data?.data || data;
        setformDataState(customerDataUpdateFormatter(responseData, formDataState));
    };

    const phoneDataUpdate = (data) => {
        const responseData = data?.data || data;
        setformDataState(phoneDataUpdateFormatter(responseData, formDataState));
    };

    const customerPhoneCreate = async (data) => {
        var apiData = phoneCreateFormatter(data, formDataState);

        if (apiData) {
            await customerPhoneCreateMutation[0]({
                customer_id: apiData["id"],
                phone_number: apiData["phone"],
            }).unwrap();
        }
    };

    const phoneCreateSuccess = (data) => {
        phoneDataUpdate(data);
    };
    useEffect(() => {
        if (customerPhoneCreateMutation[1].isSuccess) {
            phoneCreateSuccess(customerPhoneCreateMutation[1]);
        }
        if (customerPhoneCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(customerPhoneCreateMutation[1]), { variant: "error" });
        }
    }, [customerPhoneCreateMutation]);

    const emailDataUpdate = (data) => {
        const responseData = data?.data || data;
        setformDataState(emailDataUpdateFormatter(responseData, formDataState));
    };

    const customerEmailCreate = async (data) => {
        var apiData = emailCreateFormatter(data, formDataState);

        if (apiData) {
            await customerEmailCreateMutation[0]({
                customer_id: apiData["id"],
                email_address: apiData["email"],
            }).unwrap();
        }
    };

    const emailCreateSuccess = (data) => {
        emailDataUpdate(data);
    };
    useEffect(() => {
        if (customerEmailCreateMutation[1].isSuccess) {
            emailCreateSuccess(customerEmailCreateMutation[1]);
        }
        if (customerEmailCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(customerEmailCreateMutation[1]), { variant: "error" });
        }
    }, [customerEmailCreateMutation]);

    const customerCreate = async (data) => {
        var apiData = data;

        if (apiData && apiData["name"]) {
            await customerCreateMutation[0]({ name: apiData["name"], channel: apiData["channel"] }).unwrap();
        }
    };

    const customerCreateSuccess = (data) => {
        customerEmailCreate(data);
        customerPhoneCreate(data);
        customerDataUpdate(data);
    };
    useEffect(() => {
        if (customerCreateMutation[1].isSuccess) {
            customerCreateSuccess(customerCreateMutation[1]);
        }
        if (customerCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(customerCreateMutation[1]), { variant: "error" });
        }
    }, [customerCreateMutation]);

    const createCustomer = (data) => {
        customerCreate(data);

        customerAdded(data);
    };

    const fromBack = () => {
        let formCountTemp = parseInt(formCount) - 1;
        setformCount(formCountTemp);
    };

    const formBack = (data) => {
        fromBack(data);
    };

    const saveProductVariantListData = (data) => {
        var formatedData = {
            ...productVariantListFormatter(data?.data || data, "productVariantListData"),
        };
        dispatch(setStore(formatedData));
    };

    const productVariantListSuccess = (data) => {
        saveProductVariantListData(data);
    };
    useEffect(() => {
        if (productVariantListQuery.isSuccess) {
            productVariantListSuccess(productVariantListQuery);
        } else if (productVariantListQuery.isError) {
            productVariantListSuccess(null);
        }
    }, [productVariantListQuery]);
    const productVariantList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setproductVariantListSkip(false);
    };

    const orderItemListData = (data) => {
        const responseData = data?.data || data;
        setproductFormData(orderItemListFormatter(responseData));
    };

    const orderItemListSuccess = (data) => {
        orderItemListData(data);
    };
    useEffect(() => {
        if (orderItemListQuery.isSuccess) {
            orderItemListSuccess(orderItemListQuery);
        } else if (orderItemListQuery.isError) {
            orderItemListSuccess(null);
        }
    }, [orderItemListQuery]);
    const orderItemList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setorderItemListParams({ order_id: id });
        setorderItemListSkip(false);
    };

    const paymentListData = (data) => {
        const responseData = data?.data || data;
        setPaymentformState(paymentListFormatter(responseData));
    };

    const paymentListSuccess = (data) => {
        paymentListData(data);
    };
    useEffect(() => {
        if (paymentListQuery.isSuccess) {
            paymentListSuccess(paymentListQuery);
        } else if (paymentListQuery.isError) {
            paymentListSuccess(null);
        }
    }, [paymentListQuery]);
    const paymentList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpaymentListParams({ order_id: id });
        setpaymentListSkip(false);
    };

    const shipmentListData = (data) => {
        const responseData = data?.data || data;
        setshipmentformState(shipmentListFormatter(responseData));
    };

    const shipmentListSuccess = (data) => {
        shipmentListData(data);
    };
    useEffect(() => {
        if (shipmentListQuery.isSuccess) {
            shipmentListSuccess(shipmentListQuery);
        } else if (shipmentListQuery.isError) {
            shipmentListSuccess(null);
        }
    }, [shipmentListQuery]);
    const shipmentList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setshipmentListParams({ order_id: id });
        setshipmentListSkip(false);
    };

    const orderDetailsData = (data) => {
        const responseData = data?.data || data;
        setformDataState(orderDetailsFormatter(responseData));
    };

    const orderDetailsSuccess = (data) => {
        orderDetailsData(data);
    };
    useEffect(() => {
        if (orderDetailsQuery.isSuccess) {
            orderDetailsSuccess(orderDetailsQuery);
        } else if (orderDetailsQuery.isError) {
            orderDetailsSuccess(null);
        }
    }, [orderDetailsQuery]);
    const orderDetails = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setorderDetailsParams({ id: id });
        setorderDetailsSkip(false);
    };

    const customerListListData = (data) => {
        var formatedData = {
            ...customerListFormatter(data?.data || data, "customerListData"),
        };
        dispatch(setStore(formatedData));
    };

    const customerListSuccess = (data) => {
        customerListListData(data);
        orderDetails(data);
        shipmentList(data);
        paymentList(data);
        orderItemList(data);
    };
    useEffect(() => {
        if (customerListQuery.isSuccess) {
            customerListSuccess(customerListQuery);
        } else if (customerListQuery.isError) {
            customerListSuccess(null);
        }
    }, [customerListQuery]);
    const customerList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcustomerListSkip(false);
    };

    useEffect(() => {
        customerList();
        productVariantList();
    }, [id, id, id, id]);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} xs={12} justifyContent="center" sx={{ px: 1, py: 2 }}>
                    <PMStepper
                        activeStep={formCount}
                        steps={[
                            { label: "Customer Details" },
                            { label: "Payment Details" },
                            { label: "Shipment Details" },
                            { label: "Order items" },
                            { label: "Review" },
                        ]}
                    ></PMStepper>
                </PMGrid>

                <PMGrid container={true} xs={12} sx={{ px: 1, py: 2 }}>
                    {formCount != 0 ? <PMButton label="← Back" onClick={formBack}></PMButton> : ""}
                </PMGrid>

                <PMGrid container={true} xs={12}>
                    {formCount == 0 ? (
                        <FormWidget
                            direction="column"
                            header="Update Order"
                            defaultValues={formDataState}
                            submitButtonLabel="continue →"
                            Onsubmit={createCustomer}
                            Onchange={watchFormChange}
                            fieldsets={[
                                {
                                    direction: "row",
                                    fields: [
                                        { label: "Id", name: "id", type: "hidden" },
                                        {
                                            label: "Channel",
                                            md: "4",
                                            name: "channel",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: "AMAZON", label: "Amazon" },
                                                { id: "BIGBASKET", label: "Big Basket" },
                                                { id: "RETAIL", label: "Retail" },
                                                { id: "WEBSITE", label: "Website" },
                                            ],
                                        },
                                        {
                                            label: "Customer",
                                            md: "4",
                                            name: "customer_id",
                                            type: "select",
                                            options: customerListData,
                                        },
                                    ],
                                },
                                {
                                    direction: "row",
                                    displayon: formDataState?.customer_id,
                                    header: "Add Customer",
                                    fields: [
                                        {
                                            label: "Customer Name",
                                            md: "4",
                                            name: "name",
                                            type: "text",
                                            required: true,
                                            validation: { minLength: 2 },
                                        },
                                        { label: "Email", md: "4", name: "email", type: "email", required: true },
                                        {
                                            label: "Phone Number",
                                            md: "4",
                                            name: "phone",
                                            type: "number",
                                            required: true,
                                            validation: { minLength: 10, maxLength: 10, pattern: "^\\d+$" },
                                        },
                                    ],
                                },
                                {
                                    direction: "row",
                                    displayon: !formDataState?.customer_id,
                                    header: "Customer Details",
                                    fields: [
                                        {
                                            label: "Customer Name",
                                            md: "4",
                                            name: "placeholder_name",
                                            type: "display",
                                            readOnly: true,
                                        },
                                        {
                                            label: "Email",
                                            md: "4",
                                            name: "email_id",
                                            type: "select",
                                            options: customerEmailListData,
                                            required: true,
                                        },
                                        {
                                            label: "Phone Number",
                                            md: "4",
                                            name: "phone_id",
                                            type: "select",
                                            options: customerPhoneListData,
                                            required: true,
                                        },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}
                    {formCount == 1 ? (
                        <FormWidget
                            direction="column"
                            header="Payment Details"
                            submitButtonLabel="continue →"
                            defaultValues={PaymentformState}
                            Onsubmit={customerAddressCreateApi}
                            Onchange={watchPaymentFormChange}
                            fieldsets={[
                                {
                                    direction: "row",
                                    header: "Select From Existing Address",
                                    fields: [
                                        { label: "id", name: "id", type: "hidden" },
                                        {
                                            label: "Select Address",
                                            md: "4",
                                            name: "payment_address_id",
                                            type: "select",
                                            options: customerAddressListData,
                                        },
                                    ],
                                },
                                {
                                    direction: "row",
                                    fields: [
                                        { label: "Address", name: "placeholder_address", type: "display" },
                                        { label: "City", name: "placeholder_city", type: "display" },
                                        { label: "State", md: "4", name: "placeholder_state", type: "display" },
                                        { label: "Pin Code", md: "4", name: "placeholder_pincode", type: "display" },
                                    ],
                                },
                                {
                                    direction: "row",
                                    displayon: PaymentformState?.payment_address_id,
                                    header: "Create New Address",
                                    fields: [
                                        { label: "Address", name: "address", type: "text", required: true },
                                        { label: "City", name: "city", type: "text", required: true },
                                        { label: "State", md: "4", name: "state", type: "text", required: true },
                                        {
                                            label: "Pin Code",
                                            md: "4",
                                            name: "pincode",
                                            type: "number",
                                            required: true,
                                            validation: { minLength: 6, maxLength: 6, pattern: "^\\d+$" },
                                        },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}
                    {formCount == 2 ? (
                        <FormWidget
                            direction="column"
                            header="Shipment Details"
                            submitButtonLabel="continue →"
                            defaultValues={shipmentformState}
                            Onsubmit={shipAddressCreateApi}
                            Onchange={watchShipmentFormChange}
                            fieldsets={[
                                {
                                    direction: "row",
                                    header: "Select From existing Address",
                                    fields: [
                                        { label: "id", name: "id", type: "hidden" },
                                        {
                                            label: "Same as Payment Address",
                                            md: "4",
                                            name: "same_address",
                                            type: "checkbox",
                                        },
                                        {
                                            label: "Select Address",
                                            md: "4",
                                            name: "shipment_address_id",
                                            type: "select",
                                            options: customerAddressListData,
                                        },
                                    ],
                                },
                                {
                                    direction: "row",
                                    fields: [
                                        { label: "Address", name: "placeholder_address", type: "display" },
                                        { label: "City", name: "placeholder_city", type: "display" },
                                        { label: "State", md: "4", name: "placeholder_state", type: "display" },
                                        { label: "Pin Code", md: "4", name: "placeholder_pincode", type: "display" },
                                    ],
                                },
                                {
                                    direction: "row",
                                    displayon: shipmentformState?.shipment_address_id,
                                    header: "Create New Address",
                                    fields: [
                                        { label: "Address", name: "address", type: "text", required: true },
                                        { label: "City", name: "city", type: "text", required: true },
                                        { label: "State", md: "4", name: "state", type: "text", required: true },
                                        {
                                            label: "Pin Code",
                                            md: "4",
                                            name: "pincode",
                                            type: "number",
                                            required: true,
                                            validation: { minLength: 6, maxLength: 6, pattern: "^\\d+$" },
                                        },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}
                    {formCount == 3 ? (
                        <FormWidget
                            direction="column"
                            header="Add Products"
                            submitButtonLabel="continue →"
                            defaultValues={productFormData}
                            Onsubmit={nextCounter}
                            Onchange={watchProductFormChange}
                            fieldsets={[
                                {
                                    direction: "row",
                                    group: "products",
                                    additiontitle: "+ Add Product",
                                    header: "Add items",
                                    delete: true,
                                    grid: 3,
                                    count: productFormData.products.length,
                                    fields: [
                                        { label: "id", name: "id", type: "hidden" },
                                        {
                                            label: "Select Product",
                                            name: "product_variant_id",
                                            type: "select",
                                            options: productVariantListData,
                                            required: true,
                                        },
                                        {
                                            label: "Quantity",
                                            name: "quantity",
                                            type: "number",
                                            required: true,
                                            validation: { min: 1, pattern: "^\\d+$" },
                                        },
                                        { label: "Price/Unit", name: "price", type: "display", prefix: "₹" },
                                        { label: "Total Price", name: "product_total", type: "display", prefix: "₹" },
                                    ],
                                },
                                {
                                    direction: "row",
                                    fields: [
                                        { label: "Order Total", name: "order_total", type: "display", prefix: "₹" },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}
                    {formCount == 4 ? (
                        <PMGrid container={true} xs={6}>
                            {formCount == 4 ? (
                                <DetailWidget
                                    direction="row"
                                    xs={12}
                                    justifyContent="center"
                                    fields={[
                                        { label: "Customer Name", value: formDataState?.placeholder_name },
                                        { label: "Email", value: formDataState?.placeholder_email },
                                        { label: "Phone", value: formDataState?.placeholder_phone },
                                    ]}
                                ></DetailWidget>
                            ) : (
                                ""
                            )}
                            {formCount == 4 ? (
                                <DetailWidget
                                    direction="row"
                                    xs={12}
                                    justifyContent="center"
                                    fields={[
                                        { label: "Payment Address", value: PaymentformState?.placeholder_address },
                                        { label: "Payment City", value: PaymentformState?.placeholder_city },
                                        { label: "Payment State", value: PaymentformState?.placeholder_state },
                                        { label: "Payment Pincode", value: PaymentformState?.placeholder_pincode },
                                    ]}
                                ></DetailWidget>
                            ) : (
                                ""
                            )}
                            {formCount == 4 ? (
                                <DetailWidget
                                    direction="row"
                                    justifyContent="center"
                                    xs={12}
                                    fields={[
                                        { label: "Shipment Address", value: shipmentformState?.placeholder_address },
                                        { label: "Shipment City", value: shipmentformState?.placeholder_city },
                                        { label: "Shipment State", value: shipmentformState?.placeholder_state },
                                        { label: "Shipment Pincode", value: shipmentformState?.placeholder_pincode },
                                    ]}
                                ></DetailWidget>
                            ) : (
                                ""
                            )}
                        </PMGrid>
                    ) : (
                        ""
                    )}
                    {formCount == 4 ? (
                        <PMGrid container={true} xs={6}>
                            <TableWidget
                                showSerialNumber={false}
                                showQuickFilter={false}
                                hideFooter={true}
                                xs={12}
                                rows={productFormData?.products || []}
                                columns={[
                                    { field: "product_name", headerName: "Product" },
                                    { field: "quantity", headerName: "Quantity" },
                                    { field: "price", headerName: "Price", type: "currency" },
                                    { field: "product_total", headerName: "Total", type: "currency" },
                                ]}
                            ></TableWidget>
                        </PMGrid>
                    ) : (
                        ""
                    )}
                    {formCount == 4 ? (
                        <FormWidget
                            direction="column"
                            header="Add Products"
                            submitButtonLabel="Update Order"
                            defaultValues={reviewFormData}
                            Onsubmit={createOrderApi}
                            Onchange={watchReviewFormChange}
                            fieldsets={[
                                {
                                    direction: "row",
                                    header: "Order Status",
                                    fields: [
                                        {
                                            label: "Shipping Method",
                                            md: "4",
                                            name: "shipping_method",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: "FREE_SHIPPING", label: "Free Shipping" },
                                                { id: "FLAT_30_RS_SHIPMENT", label: "Flat 30 Rs Shipment" },
                                            ],
                                        },
                                        { label: "Shipment Tracking ID", md: "4", name: "tracking_id", type: "text" },
                                        {
                                            label: "Payment Date",
                                            md: "4",
                                            name: "payment_date",
                                            type: "date",
                                            required: true,
                                        },
                                        {
                                            label: "Payment Method",
                                            md: "4",
                                            name: "payment_mode",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: "RAZORPAY", label: "Razorpay" },
                                                { id: "PAYTM", label: "Paytm" },
                                                { id: "CREDITPERIOD", label: "Creditperiod" },
                                                { id: "FREEORDER", label: "Freeorder" },
                                                { id: "CASH", label: "Cash" },
                                                { id: "CREDITCARD", label: "Creditcard" },
                                                { id: "DEBITCARD", label: "Debitcard" },
                                                { id: "NETBANKING", label: "Netbanking" },
                                            ],
                                        },
                                        {
                                            label: "Order Status",
                                            md: "4",
                                            name: "order_status",
                                            required: true,
                                            type: "select",
                                            options: [
                                                { id: "OPEN", label: "Open" },
                                                { id: "PENDING", label: "Pending" },
                                                { id: "PROCESSED", label: "Processed" },
                                                { id: "SHIPPED", label: "Shipped" },
                                                { id: "COMPLETE", label: "Complete" },
                                                { id: "CANCELLED", label: "Cancelled" },
                                                { id: "EXPIRED", label: "Expired" },
                                                { id: "MISSINGORDER", label: "Missingorder" },
                                                { id: "RETURNED", label: "Returned" },
                                                { id: "FAILED", label: "Failed" },
                                                { id: "REVERSED", label: "Reversed" },
                                            ],
                                        },
                                        { label: "Invoice No", md: "4", name: "invoice_number", type: "text" },
                                        { label: "Upload Order Invoice", md: "4", name: "file", type: "file" },
                                        { label: "Add Notes", md: "4", name: "notes", type: "text" },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default OrderUpdate;
