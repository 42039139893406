const poFormFormatter = (data, dataKey) => {
    const dataSet = {};
    dataSet[dataKey] = JSON.parse(JSON.stringify(data));
    dataSet[dataKey]["supplier"] = dataSet[dataKey]["manufacturer_id"]
    if(dataSet[dataKey]['poitem']){
        dataSet[dataKey]["product_variant"] = []
        dataSet[dataKey]['poitem'].forEach(element => {
            var product_variant = {}
            product_variant["id"] = element["id"]
            product_variant["product_variant_id"] = element["productvariant"]["id"]
            product_variant["quantity"] = element["quantity"]
            product_variant["rate"] = element["rate"]
            product_variant["received_item_status"] = "Received"
            dataSet[dataKey]["product_variant"].push(product_variant)
        });
    }
    return dataSet;
};

export default poFormFormatter;