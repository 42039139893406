import { configureStore } from "@reduxjs/toolkit";
import appStoreReducer from "./appSlice";

import { loginApiSlice } from "./loginApiSlice";

import { productListSlice } from "./productListSlice";

import { poListSlice } from "./poListSlice";

import { manufacturerSlice } from "./manufacturerSlice";

import { manufacturerDetailsSlice } from "./manufacturerDetailsSlice";

import { getAddressSlice } from "./getAddressSlice";

import { createpoSlice } from "./createpoSlice";

import { createpoitemSlice } from "./createpoitemSlice";

import { poDetailsSlice } from "./poDetailsSlice";

import { recievedItemListSlice } from "./recievedItemListSlice";

import { recievedItemSlice } from "./recievedItemSlice";

import { stocklocationListSlice } from "./stocklocationListSlice";

import { productVariantListSlice } from "./productVariantListSlice";

import { poPdfCreateSlice } from "./poPdfCreateSlice";

import { recievedItemCreateSlice } from "./recievedItemCreateSlice";

import { qualityCheckSlice } from "./qualityCheckSlice";

import { stockOutSlice } from "./stockOutSlice";

import { currentStockSlice } from "./currentStockSlice";

import { qualityCheckListSlice } from "./qualityCheckListSlice";

import { stockLedgerSlice } from "./stockLedgerSlice";

import { qualityFilesSlice } from "./qualityFilesSlice";

import { poDetailsPatchSlice } from "./poDetailsPatchSlice";

import { poItemPatchSlice } from "./poItemPatchSlice";

import { chengePoStatusSlice } from "./chengePoStatusSlice";

import { orderListSlice } from "./orderListSlice";

import { customerListSlice } from "./customerListSlice";

import { customerAddressListSlice } from "./customerAddressListSlice";

import { customerCreateSlice } from "./customerCreateSlice";

import { customerEmailListSlice } from "./customerEmailListSlice";

import { customerPhoneListSlice } from "./customerPhoneListSlice";

import { customerPhoneCreateSlice } from "./customerPhoneCreateSlice";

import { customerEmailCreateSlice } from "./customerEmailCreateSlice";

import { customerAddressCreateSlice } from "./customerAddressCreateSlice";

import { orderCreateSlice } from "./orderCreateSlice";

import { orderUpdateSlice } from "./orderUpdateSlice";

import { shipmentCreateSlice } from "./shipmentCreateSlice";

import { paymentCreateSlice } from "./paymentCreateSlice";

import { orderItemCreateSlice } from "./orderItemCreateSlice";

import { orderItemUpdateSlice } from "./orderItemUpdateSlice";

import { pricingListSlice } from "./pricingListSlice";

import { pricingUpdateSlice } from "./pricingUpdateSlice";

import { productVariantDetailSlice } from "./productVariantDetailSlice";

import { notesCreateSlice } from "./notesCreateSlice";

import { orderDetailsSlice } from "./orderDetailsSlice";

import { shipmentListSlice } from "./shipmentListSlice";

import { paymentListSlice } from "./paymentListSlice";

import { shipmentUpdateSlice } from "./shipmentUpdateSlice";

import { paymentUpdateSlice } from "./paymentUpdateSlice";

import { orderItemListSlice } from "./orderItemListSlice";

export default configureStore({
    reducer: {
        appStore: appStoreReducer,
        [loginApiSlice.reducerPath]: loginApiSlice.reducer,
        [productListSlice.reducerPath]: productListSlice.reducer,
        [poListSlice.reducerPath]: poListSlice.reducer,
        [manufacturerSlice.reducerPath]: manufacturerSlice.reducer,
        [manufacturerDetailsSlice.reducerPath]: manufacturerDetailsSlice.reducer,
        [getAddressSlice.reducerPath]: getAddressSlice.reducer,
        [createpoSlice.reducerPath]: createpoSlice.reducer,
        [createpoitemSlice.reducerPath]: createpoitemSlice.reducer,
        [poDetailsSlice.reducerPath]: poDetailsSlice.reducer,
        [recievedItemListSlice.reducerPath]: recievedItemListSlice.reducer,
        [recievedItemSlice.reducerPath]: recievedItemSlice.reducer,
        [stocklocationListSlice.reducerPath]: stocklocationListSlice.reducer,
        [productVariantListSlice.reducerPath]: productVariantListSlice.reducer,
        [poPdfCreateSlice.reducerPath]: poPdfCreateSlice.reducer,
        [recievedItemCreateSlice.reducerPath]: recievedItemCreateSlice.reducer,
        [qualityCheckSlice.reducerPath]: qualityCheckSlice.reducer,
        [stockOutSlice.reducerPath]: stockOutSlice.reducer,
        [currentStockSlice.reducerPath]: currentStockSlice.reducer,
        [qualityCheckListSlice.reducerPath]: qualityCheckListSlice.reducer,
        [stockLedgerSlice.reducerPath]: stockLedgerSlice.reducer,
        [qualityFilesSlice.reducerPath]: qualityFilesSlice.reducer,
        [poDetailsPatchSlice.reducerPath]: poDetailsPatchSlice.reducer,
        [poItemPatchSlice.reducerPath]: poItemPatchSlice.reducer,
        [chengePoStatusSlice.reducerPath]: chengePoStatusSlice.reducer,
        [orderListSlice.reducerPath]: orderListSlice.reducer,
        [customerListSlice.reducerPath]: customerListSlice.reducer,
        [customerAddressListSlice.reducerPath]: customerAddressListSlice.reducer,
        [customerCreateSlice.reducerPath]: customerCreateSlice.reducer,
        [customerEmailListSlice.reducerPath]: customerEmailListSlice.reducer,
        [customerPhoneListSlice.reducerPath]: customerPhoneListSlice.reducer,
        [customerPhoneCreateSlice.reducerPath]: customerPhoneCreateSlice.reducer,
        [customerEmailCreateSlice.reducerPath]: customerEmailCreateSlice.reducer,
        [customerAddressCreateSlice.reducerPath]: customerAddressCreateSlice.reducer,
        [orderCreateSlice.reducerPath]: orderCreateSlice.reducer,
        [orderUpdateSlice.reducerPath]: orderUpdateSlice.reducer,
        [shipmentCreateSlice.reducerPath]: shipmentCreateSlice.reducer,
        [paymentCreateSlice.reducerPath]: paymentCreateSlice.reducer,
        [orderItemCreateSlice.reducerPath]: orderItemCreateSlice.reducer,
        [orderItemUpdateSlice.reducerPath]: orderItemUpdateSlice.reducer,
        [pricingListSlice.reducerPath]: pricingListSlice.reducer,
        [pricingUpdateSlice.reducerPath]: pricingUpdateSlice.reducer,
        [productVariantDetailSlice.reducerPath]: productVariantDetailSlice.reducer,
        [notesCreateSlice.reducerPath]: notesCreateSlice.reducer,
        [orderDetailsSlice.reducerPath]: orderDetailsSlice.reducer,
        [shipmentListSlice.reducerPath]: shipmentListSlice.reducer,
        [paymentListSlice.reducerPath]: paymentListSlice.reducer,
        [shipmentUpdateSlice.reducerPath]: shipmentUpdateSlice.reducer,
        [paymentUpdateSlice.reducerPath]: paymentUpdateSlice.reducer,
        [orderItemListSlice.reducerPath]: orderItemListSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            loginApiSlice.middleware,
            productListSlice.middleware,
            poListSlice.middleware,
            manufacturerSlice.middleware,
            manufacturerDetailsSlice.middleware,
            getAddressSlice.middleware,
            createpoSlice.middleware,
            createpoitemSlice.middleware,
            poDetailsSlice.middleware,
            recievedItemListSlice.middleware,
            recievedItemSlice.middleware,
            stocklocationListSlice.middleware,
            productVariantListSlice.middleware,
            poPdfCreateSlice.middleware,
            recievedItemCreateSlice.middleware,
            qualityCheckSlice.middleware,
            stockOutSlice.middleware,
            currentStockSlice.middleware,
            qualityCheckListSlice.middleware,
            stockLedgerSlice.middleware,
            qualityFilesSlice.middleware,
            poDetailsPatchSlice.middleware,
            poItemPatchSlice.middleware,
            chengePoStatusSlice.middleware,
            orderListSlice.middleware,
            customerListSlice.middleware,
            customerAddressListSlice.middleware,
            customerCreateSlice.middleware,
            customerEmailListSlice.middleware,
            customerPhoneListSlice.middleware,
            customerPhoneCreateSlice.middleware,
            customerEmailCreateSlice.middleware,
            customerAddressCreateSlice.middleware,
            orderCreateSlice.middleware,
            orderUpdateSlice.middleware,
            shipmentCreateSlice.middleware,
            paymentCreateSlice.middleware,
            orderItemCreateSlice.middleware,
            orderItemUpdateSlice.middleware,
            pricingListSlice.middleware,
            pricingUpdateSlice.middleware,
            productVariantDetailSlice.middleware,
            notesCreateSlice.middleware,
            orderDetailsSlice.middleware,
            shipmentListSlice.middleware,
            paymentListSlice.middleware,
            shipmentUpdateSlice.middleware,
            paymentUpdateSlice.middleware,
            orderItemListSlice.middleware
        ),
});
