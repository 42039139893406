import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const poListSlice = createApi({
    reducerPath: "poList",
    baseQuery: fetchBaseQuery({
        baseUrl: "http://order.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        poList: builder.query({
            query: (data) => ({ url: "/inventory/api/v1/purchaseorder/list", params: data, method: "GET" }),
        }),
    }),
});

export const { usePoListQuery } = poListSlice;
