const productPricingFormatter = (data, pricingData, datakey) => {
    const dataSet = JSON.parse(JSON.stringify(data?.data || data));
    dataSet["items"].map((element) => {
        element["product_name"] = element["product"]["name"]
        pricingData["items"].forEach(ele => {
            if(ele.product_variant_id==element.id)
            element[ele["channel"]] = ele["price"]
        });
    })
    const returnData = []
    returnData[datakey] = dataSet["items"]
    return returnData
};
export default productPricingFormatter;