const currentStockFormatter = (data, dataKey) => {
    const dataSet = {};
    dataSet[dataKey] = JSON.parse(JSON.stringify(data));
    if(dataSet[dataKey]){
        dataSet[dataKey]["items"].forEach((element) => {
            if(element["productvariant"]){
               element["product_name"] = element["productvariant"]["product"]["name"];
            }
        });
        dataSet[dataKey]["low_stock"] = dataSet[dataKey]["items"].filter((ele)=>ele?.available<100 && ele?.available>0).length || 0
        dataSet[dataKey]["no_stock"] = dataSet[dataKey]["items"].filter((ele)=>ele?.available==0).length || 0
        dataSet[dataKey]["high_stock"] = dataSet[dataKey]["items"].filter((ele)=>ele?.available>=100).length || 0
    }

    return dataSet;
};

export default currentStockFormatter;