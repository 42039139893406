import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const customerAddressCreateSlice = createApi({
    reducerPath: "customerAddressCreate",
    baseQuery: fetchBaseQuery({
        baseUrl: "http://order.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        customerAddressCreate: builder.mutation({
            query: (data) => ({ url: "/order-management/api/v1/address/create", method: "POST", body: data }),
        }),
    }),
});

export const { useCustomerAddressCreateMutation } = customerAddressCreateSlice;
