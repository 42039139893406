// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useQualityCheckListQuery } from "../store/qualityCheckListSlice";
import { default as qualityCheckListFormatter } from "../transformations/qualityCheckListFormatter";
function ShipmentQualityList() {
    const [qualityCheckListSkip, setqualityCheckListSkip] = useState(true);
    const [qualityCheckListParams, setqualityCheckListParams] = useState({});
    const qualityCheckListQuery = useQualityCheckListQuery(qualityCheckListParams, { skip: qualityCheckListSkip });
    const dispatch = useDispatch();
    const qualityCheckListData = useSelector((states) => states?.appStore?.qualityCheckListData);

    const qualityCheckListSuccess = (data) => {
        savequalityCheckListData(data);
    };
    useEffect(() => {
        if (qualityCheckListQuery.isSuccess) {
            qualityCheckListSuccess(qualityCheckListQuery);
        } else if (qualityCheckListQuery.isError) {
            qualityCheckListSuccess(null);
        }
    }, [qualityCheckListQuery]);
    const qualityCheckList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setqualityCheckListParams({ order_by: data ? data["-created_at"] : "-created_at" });
        setqualityCheckListSkip(false);
    };

    const savequalityCheckListData = (data) => {
        var formatedData = {
            ...qualityCheckListFormatter(data?.data || data, "qualityCheckListData"),
        };
        dispatch(setStore(formatedData));
    };

    useEffect(() => {
        qualityCheckList();
    }, []);

    const qualityCheckListReFetch = (tableParams) => {
        setqualityCheckListParams({ order_by: "-created_at", ...tableParams });
    };
    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Previous Stock Entry"
                        showSerialNumber={false}
                        xs={12}
                        tableApi={qualityCheckListData || []}
                        updateTable={qualityCheckListReFetch}
                        columns={[
                            { field: "shipment_date", headerName: "Delivery Date", type: "date", sortable: false },
                            { field: "created_at", headerName: "QC Date", type: "date", sortable: false },
                            { field: "product_name", headerName: "Product Name", highlight: true, sortable: false },
                            { field: "supplier_name", headerName: "Supplier Name", sortable: false },
                            { field: "batch_no", headerName: "Batch Number", sortable: false },
                            { field: "quantity", headerName: "Total Quantity Received", sortable: false },
                            { field: "leakage", headerName: "Leakage" },
                            { field: "blooted", headerName: "Bloated" },
                            { field: "other", headerName: "Other" },
                            { field: "defect_percentage", headerName: "Defective%", sortable: false },
                            {
                                field: "total_inwarded_qantity",
                                headerName: "Total Inwarded Quanity",
                                highlight: true,
                                sortable: false,
                            },
                            { field: "download_item", headerName: "Download", type: "download" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ShipmentQualityList;
