import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const recievedItemCreateSlice = createApi({
    reducerPath: "recievedItemCreate",
    baseQuery: fetchBaseQuery({
        baseUrl: "http://order.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        recievedItemCreate: builder.mutation({
            query: (data) => ({ url: "/inventory/api/v1/receiveditem/create", method: "POST", body: data }),
        }),
    }),
});

export const { useRecievedItemCreateMutation } = recievedItemCreateSlice;
