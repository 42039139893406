// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useOrderListQuery } from "../store/orderListSlice";

function OrderList() {
    const [orderListSkip, setorderListSkip] = useState(true);
    const [orderListParams, setorderListParams] = useState({});
    const orderListQuery = useOrderListQuery(orderListParams, { skip: orderListSkip });
    const dispatch = useDispatch();
    const orderListData = useSelector((states) => states?.appStore?.orderListData);

    const orderListSuccess = (data) => {
        orderListListData(data);
    };
    useEffect(() => {
        if (orderListQuery.isSuccess) {
            orderListSuccess(orderListQuery);
        } else if (orderListQuery.isError) {
            orderListSuccess(null);
        }
    }, [orderListQuery]);
    const orderList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setorderListSkip(false);
    };

    const orderListListData = (data) => {
        var formatedData = {
            orderListData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    useEffect(() => {
        orderList();
    }, []);

    const orderListReFetch = (tableParams) => {
        setorderListParams({ order_by: "-created_at", ...tableParams });
    };
    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Orders List"
                        showSerialNumber={false}
                        xs={12}
                        tableApi={orderListData || []}
                        updateTable={orderListReFetch}
                        columns={[
                            { field: "id", headerName: "Order Id" },
                            { field: "customer_name", headerName: "Customer Name" },
                            { field: "order_status", headerName: "Order Status" },
                            { field: "total_amount", headerName: "Total Order Value", type: "currency" },
                            { field: "created_at", headerName: "Order Created date", type: "date" },
                            { field: "updated_at", headerName: "Last updated on", type: "date" },
                            { headerName: "Edit", type: "edit" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default OrderList;
