import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const shipmentCreateSlice = createApi({
    reducerPath: "shipmentCreate",
    baseQuery: fetchBaseQuery({
        baseUrl: "http://order.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        shipmentCreate: builder.mutation({
            query: (data) => ({ url: "/order-management/api/v1/shipment/create", method: "POST", body: data }),
        }),
    }),
});

export const { useShipmentCreateMutation } = shipmentCreateSlice;
